import PropTypes from "prop-types";
import React from "react";
import { usePopperTooltip } from "react-popper-tooltip";

const Tooltip = ({
  tooltipBody,
  children,
  showArrow = false,
  placement = "top",
  trigger = "hover",
  tooltipShown,
  onVisibilityChange,
  followCursor
}) => {
  const {
    getTooltipProps,
    getArrowProps,
    setTooltipRef,
    setTriggerRef,
    visible,
    placement: actualPlacement
  } = usePopperTooltip({
    placement,
    trigger,
    visible: tooltipShown,
    onVisibleChange: onVisibilityChange,
    followCursor
  });

  return (
    <>
      <span ref={setTriggerRef}>{children}</span>

      {visible && (
        <div
          {...getTooltipProps({
            ref: setTooltipRef,
            className: showArrow ? `tooltip-container ${placement}` : "tooltip-container"
          })}
        >
          {showArrow && (
            <div
              {...getArrowProps({
                className: "tooltip-arrow",
                "data-placement": placement
              })}
            />
          )}
          {tooltipBody}
        </div>
      )}
    </>
  );
};

Tooltip.propTypes = {
  tooltipBody: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  showArrow: PropTypes.bool,
  placement: PropTypes.string,
  trigger: PropTypes.string,
  tooltipShown: PropTypes.bool,
  onVisibilityChange: PropTypes.func,
  followCursor: PropTypes.bool
};

export default Tooltip;
