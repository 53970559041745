import { createSelector } from "reselect";
import { CALL_API } from "../middleware/api";
import { getSessionIsNewFlow } from "./session";
import { getSessionIsKorea, getSessionUseApiV2 } from "./session";
import { getMinVirtualAmount } from "./paymentOptions";

export const LOAD_PURCHASE_CONTEXT = "pmc-client/purchase-context/LOAD_PURCHASE_CONTEXT";

export const LOAD_PURCHASE_CONTEXT_SUCCESS = "pmc-client/purchase-context/LOAD_PURCHASE_CONTEXT_SUCCESS";

export const LOAD_PURCHASE_CONTEXT_FAILED = "pmc-client/purchase-context/LOAD_PURCHASE_CONTEXT_FAILED";

export const CLEAR_PURCHASE_CONTEXT = "pmc-client/purchase-context/CLEAR_PURCHASE_CONTEXT";

export const loadPurchaseContextIfNeeded = () => {
  return (dispatch, getState) => {
    const state = getState();
    if (getSessionIsKorea(state) && !isContextLoaded(state)) {
      if (getSessionUseApiV2(state)) {
        return dispatch(loadPurchaseContextV2());
      }

      return dispatch(loadPurchaseContext());
    } else if (getSessionIsNewFlow(state) && !isContextLoaded(state)) {
      return dispatch(loadPurchaseContextV2());
    }

    // Consistent return
    return undefined;
  };
};

export const clearPurchaseContext = () => {
  return {
    type: CLEAR_PURCHASE_CONTEXT
  };
};

export const loadPurchaseContext = () => ({
  [CALL_API]: {
    types: [LOAD_PURCHASE_CONTEXT, LOAD_PURCHASE_CONTEXT_SUCCESS, LOAD_PURCHASE_CONTEXT_FAILED],
    endpoint: "/riotpay/pmc/v1/purchase-context"
  }
});

export const loadPurchaseContextV2 = () => ({
  [CALL_API]: {
    types: [LOAD_PURCHASE_CONTEXT, LOAD_PURCHASE_CONTEXT_SUCCESS, LOAD_PURCHASE_CONTEXT_FAILED],
    endpoint: "/riotpay/pmc/v2/purchase-context"
  }
});

export const initialState = {
  loaded: false,
  loading: false,
  context: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_PURCHASE_CONTEXT: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LOAD_PURCHASE_CONTEXT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        context: action.response
      };
    }
    case CLEAR_PURCHASE_CONTEXT: {
      return initialState;
    }
    default:
      return state;
  }
}

export const getPurchaseContext = (state) => state.purchaseContext.context;
export const isContextLoaded = (state) => state.purchaseContext.loaded;

// Context Awareness is available if minVirtualAmount
// and gameContentMetadataJson are initialized in the RiotPay session and available
export const isContextAwarenessAvailable = createSelector(
  [getPurchaseContext, getMinVirtualAmount],
  (context, minVirtualAmount) => {
    if (!context || !minVirtualAmount || minVirtualAmount <= 0) {
      return false;
    }

    try {
      const metadata = JSON.parse(context.gameContentMetadataJson);
      return metadata != null;
    } catch {
      return false;
    }
  }
);
