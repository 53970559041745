export default class Rfc190Scope {
  constructor(environment, datacenter, deployment, product, component) {
    if (!environment) throw new Error("environment is required but was null or undefined");
    if (!datacenter) throw new Error("datacenter is required but was null or undefined");
    if (!deployment) throw new Error("deployment is required but was null or undefined");
    if (!product) throw new Error("product is required but was null or undefined");
    if (!component) throw new Error("component is required but was null or undefined");

    this.environment = environment;
    this.datacenter = datacenter;
    this.deployment = deployment;
    this.product = product;
    this.component = component;
  }

  static of(fullScope) {
    if (typeof fullScope !== "string") {
      throw new Error("fullScope must be a string");
    }

    const parts = fullScope
      .split(".")
      .map((part) => part.trim())
      .filter((part) => part.length > 0);

    if (parts.length !== 5) {
      throw new Error("Full scope must have 5 components separated by '.'");
    }

    return new Rfc190Scope(parts[0], parts[1], parts[2], parts[3], parts[4]);
  }
}
