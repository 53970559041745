import React from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";

import "./PurchasePaymentMethodRow.css";
import PurchasePaymentMethodTabs from "./PurchasePaymentMethodTabs";
import PurchasePaymentMethodCard from "./PurchasePaymentMethodCard";
import tossLogo from "../../static/img/kr/toss_logo.png";
import tossPayLogo from "../../static/img/kr/toss_tosspay_logo.png";
import naverPayLogo from "../../static/img/kr/naverpay_logo.png";
import kakaoLogo from "../../static/img/kr/kakao_logo.png";
import PurchasePaymentMethodDefault from "./PurchasePaymentMethodDefault";

const PurchasePaymentMethodRow = ({
  paymentOptions,
  onPaymentMethodSelect,
  onSelectCardBank,
  selectedPaymentMethod,
  isWindows,
  virtualCurrencyCode,
  intl
}) => {
  const mobilePaymentMethods = {
    "toss-tosspay": {
      title: intl.formatMessage({ id: "kr_title_toss-tosspay", defaultMessage: "토스" }),
      img: tossPayLogo,
      alt: "toss"
    },
    kakaopay: {
      title: intl.formatMessage({ id: "kr_title_kakaopay", defaultMessage: "카카오페이" }),
      img: kakaoLogo,
      alt: "kakaopay"
    },
    naverpay: {
      img: naverPayLogo,
      title: intl.formatMessage({ id: "naverpay", defaultMessage: "네이버페이" })
    },
    "pay1q-toss": {
      title: intl.formatMessage({ id: "kr_title_pay1q-toss", defaultMessage: "pay1q-토스" }),
      img: tossLogo,
      alt: "pay1q-toss"
    },
    "pay1q-payco": {
      title: intl.formatMessage({ id: "kr_title_pay1q-payco", defaultMessage: "PAYCO" })
    }
  };
  const tossCreditCardPaymentMethods = {
    "toss-brandpaycard": {
      title: intl.formatMessage({ id: "kr_title_toss-brandpaycard", defaultMessage: "간편 카드 결제" })
    },
    "toss-creditcard": {
      title: intl.formatMessage({ id: "kr_title_toss-creditcard", defaultMessage: "일반 카드 결제" })
    }
  };
  const miscPaymentMethods = {
    "toss-brandpaybank": {
      title: intl.formatMessage({ id: "kr_title_toss-brandpaybank", defaultMessage: "간편 계좌 이체" })
    },
    "pay1q-kftc": {
      title: intl.formatMessage({ id: "kr_title_pay1q-kftc", defaultMessage: "계좌이체" })
    },
    "pay1q-vacct": {
      title: intl.formatMessage({ id: "kr_title_pay1q-vacct", defaultMessage: "가상계좌" })
    },
    "pay1q-impaymobile": {
      title: intl.formatMessage({ id: "kr_title_pay1q-impaymobile", defaultMessage: "휴대폰 결제" })
    },
    "pay1q-culture": {
      title: intl.formatMessage({ id: "kr_title_pay1q-culture", defaultMessage: "컬쳐랜드상품권" })
    },
    "pay1q-teencash": {
      title: intl.formatMessage({ id: "kr_title_pay1q-teencash", defaultMessage: "틴캐시" })
    },
    "pay1q-happymoney": {
      title: intl.formatMessage({ id: "kr_title_pay1q-happymoney", defaultMessage: "해피머니상품권" })
    },
    "pay1q-book": {
      title: intl.formatMessage({ id: "kr_title_pay1q-book", defaultMessage: "도서문화상품권" })
    },
    "pay1q-smartculture": {
      title: intl.formatMessage({ id: "kr_title_pay1q-smartculture", defaultMessage: "스마트문상" })
    },
    cybercvs: {
      title: intl.formatMessage(
        { id: "kr_title_cybercvs", defaultMessage: "편의점 RP" },
        { vc_code: intl.formatMessage({ id: `vc_code_${virtualCurrencyCode}`, defaultMessage: virtualCurrencyCode }) }
      )
    },
    "pay1q-cyberposa": {
      title: intl.formatMessage({ id: "kr_title_pay1q-cyberposa", defaultMessage: "LoL 선불카드" })
    }
  };

  const enabledPaymentMethods = paymentOptions.map((e) => e.paymentMethodId);

  const anyPaymentMethodsExist = (names) => {
    for (let i = 0; i < names.length; i++) {
      if (enabledPaymentMethods.indexOf(names[i]) > -1) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="payment-method-row">
      <dl>
        <dt>
          <FormattedMessage id="kr_purchase_payment_methods" defaultMessage="결제 수단" />
        </dt>
      </dl>
      <dd>
        <PurchasePaymentMethodTabs onSelectPaymentMethod={onPaymentMethodSelect}>
          <div
            tab="mobile-payment"
            label={intl.formatMessage({
              id: "kr_purchase_payment_methods_mobile_payment_tab",
              defaultMessage: "간편결제"
            })}
            enabled={anyPaymentMethodsExist(Object.keys(mobilePaymentMethods))}
          >
            <PurchasePaymentMethodDefault
              defaultPaymentMethods={mobilePaymentMethods}
              enabledPaymentMethods={enabledPaymentMethods}
              onSelectPaymentMethod={onPaymentMethodSelect}
              selectedPaymentMethod={selectedPaymentMethod}
              bodyClassName={"mobile-payment"}
              headerIntlId={"kr_purchase_payment_methods_mobile_payment_title"}
              showTitle={true}
              alignMiddle={true}
            />
          </div>
          <div
            tab="credit-card"
            label={intl.formatMessage({
              id: "kr_purchase_payment_methods_credit_card_tab",
              defaultMessage: "신용카드"
            })}
            enabled={anyPaymentMethodsExist(["inicis-creditcard"])}
          >
            <PurchasePaymentMethodCard
              onSelectCardBank={onSelectCardBank}
              selectedCreditCardBank={selectedPaymentMethod.bank}
              isWindows={isWindows}
            />
          </div>
          <div
            tab="toss-credit-card"
            label={intl.formatMessage({
              id: "kr_purchase_payment_methods_toss_credit_card_tab",
              defaultMessage: "결제하실 방식을 선택하세요."
            })}
            enabled={anyPaymentMethodsExist(Object.keys(tossCreditCardPaymentMethods))}
          >
            <PurchasePaymentMethodDefault
              defaultPaymentMethods={tossCreditCardPaymentMethods}
              enabledPaymentMethods={enabledPaymentMethods}
              onSelectPaymentMethod={onPaymentMethodSelect}
              selectedPaymentMethod={selectedPaymentMethod}
              bodyClassName={"toss"}
              headerIntlId={"kr_purchase_payment_methods_toss_credit_card_title"}
              showTitle={true}
              alignMiddle={false}
            />
          </div>
          <div
            tab="misc"
            label={intl.formatMessage({
              id: "kr_purchase_payment_methods_misc_tab",
              defaultMessage: "기타"
            })}
            enabled={anyPaymentMethodsExist(Object.keys(miscPaymentMethods))}
          >
            <PurchasePaymentMethodDefault
              defaultPaymentMethods={miscPaymentMethods}
              enabledPaymentMethods={enabledPaymentMethods}
              onSelectPaymentMethod={onPaymentMethodSelect}
              selectedPaymentMethod={selectedPaymentMethod}
              bodyClassName={"misc"}
              headerIntlId={"kr_purchase_payment_methods_misc_title"}
              showTitle={true}
              alignMiddle={false}
            />
          </div>
        </PurchasePaymentMethodTabs>
      </dd>
    </div>
  );
};

PurchasePaymentMethodRow.propTypes = {
  onPaymentMethodSelect: PropTypes.func.isRequired,
  onSelectCardBank: PropTypes.func.isRequired,
  paymentOptions: PropTypes.array.isRequired,
  selectedPaymentMethod: PropTypes.object.isRequired,
  isWindows: PropTypes.bool.isRequired,
  virtualCurrencyCode: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired
};

export default injectIntl(PurchasePaymentMethodRow);
