import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import selectFirstAvailablePaymentMethod from "../../utils/krSelectFirstAvailablePaymentMethod";

class PurchasePaymentMethodDefault extends React.Component {
  constructor(props) {
    super(props);

    const selectedPaymentMethod = selectFirstAvailablePaymentMethod(
      props.defaultPaymentMethods,
      props.enabledPaymentMethods
    );

    props.onSelectPaymentMethod(selectedPaymentMethod.name, selectedPaymentMethod.title);
  }

  isEnabled = (paymentMethod) => {
    return this.props.enabledPaymentMethods.indexOf(paymentMethod) > -1;
  };

  onSelectPaymentMethod = (event) => {
    this.props.onSelectPaymentMethod(event.target.value, event.target.title);
  };

  render() {
    const { defaultPaymentMethods, selectedPaymentMethod, bodyClassName, headerIntlId, showTitle, alignMiddle } =
      this.props;
    const options = (
      <ul>
        {Object.keys(defaultPaymentMethods)
          .filter(this.isEnabled)
          .map((method) => (
            <li key={method}>
              <label>
                <input
                  type="radio"
                  value={method}
                  title={defaultPaymentMethods[method].title}
                  checked={selectedPaymentMethod.name === method}
                  onChange={this.onSelectPaymentMethod}
                  name={bodyClassName}
                />
                {defaultPaymentMethods[method].img ? (
                  <img src={defaultPaymentMethods[method].img} alt={defaultPaymentMethods[method].alt} />
                ) : (
                  defaultPaymentMethods[method].title
                )}
              </label>
            </li>
          ))}
      </ul>
    );
    return (
      <div className={bodyClassName}>
        <h3>
          <FormattedMessage id={headerIntlId} />
        </h3>
        {alignMiddle ? (
          <div className="options">
            {showTitle && (
              <span className="subtitle">
                <FormattedMessage id="kr_purchase_payment_method" defaultMessage="결제 방식" />
              </span>
            )}
            {options}
          </div>
        ) : (
          options
        )}
      </div>
    );
  }
}

PurchasePaymentMethodDefault.propTypes = {
  defaultPaymentMethods: PropTypes.object.isRequired,
  enabledPaymentMethods: PropTypes.array,
  onSelectPaymentMethod: PropTypes.func.isRequired,
  selectedPaymentMethod: PropTypes.object.isRequired,
  bodyClassName: PropTypes.string.isRequired,
  headerIntlId: PropTypes.string.isRequired,
  showTitle: PropTypes.bool.isRequired,
  alignMiddle: PropTypes.bool.isRequired
};

export default PurchasePaymentMethodDefault;
