import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";

import reducers from "./modules/reducers";
import apiMiddleware from "./middleware/api";
import { thunk } from "redux-thunk";

export default (initialState, client) => {
  let enhancer = composeWithDevTools(applyMiddleware(thunk, apiMiddleware(client)));

  return createStore(reducers, initialState, enhancer);
};
