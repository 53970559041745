import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import resolveIcon from "../utils/iconResolver";
import { connect } from "react-redux";
import { getSessionToken } from "../redux/modules/session";
import analyticsClient from "../utils/analyticsPlatformClient";

class PaymentMethodItem extends React.PureComponent {
  onPaymentMethodSelect = () => {
    analyticsClient.sendEvent("paymentMethodClickedEvent", this.props.sessionToken, {
      paymentMethod: this.props.paymentMethodId,
      position: this.props.position
    });

    this.props.onPaymentMethodSelect(this.props.uniquePaymentMethodId);
  };

  render() {
    const { selected, paymentMethodId, platform, country, locale, currency } = this.props;
    return (
      <li onClick={this.onPaymentMethodSelect} className={classNames({ selected: selected })}>
        <img src={`${resolveIcon(paymentMethodId, platform, country, locale, currency)}`} alt={paymentMethodId} />
      </li>
    );
  }
}

PaymentMethodItem.propTypes = {
  onPaymentMethodSelect: PropTypes.func.isRequired,
  country: PropTypes.object.isRequired,
  platform: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  paymentMethodId: PropTypes.string.isRequired,
  uniquePaymentMethodId: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
  sessionToken: getSessionToken(state)
});

export default connect(mapStateToProps)(PaymentMethodItem);
