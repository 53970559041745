import React, { Component } from "react";
import PropTypes from "prop-types";

import PurchasePaymentMethodTab from "./PurchasePaymentMethodTab";

class PurchasePaymentMethodTabs extends Component {
  constructor(props) {
    super(props);

    const findFirstActiveTab = (children) => {
      for (let i = 0; i < children.length; i++) {
        let child = children[i];
        if (child.props.enabled) {
          return child.props.tab;
        }
      }

      return undefined;
    };

    this.state = {
      activeTab: findFirstActiveTab(props.children)
    };
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
    if (tab === "dcb") {
      this.props.onSelectPaymentMethod("pay1q-impaymobile", "휴대폰");
    } else if (tab === "credit-card") {
      this.props.onSelectPaymentMethod("inicis-creditcard");
    } else if (tab === "toss-credit-card") {
      this.props.onSelectPaymentMethod("toss-creditcard");
    } else {
      this.props.onSelectPaymentMethod("");
    }
  };

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab }
    } = this;

    return (
      <div className="payment-method-tabs">
        <ol className="tab-list">
          {children.map((child) => {
            const { label, enabled, tab } = child.props;
            if (!enabled) {
              return undefined;
            }
            return (
              <PurchasePaymentMethodTab
                activeTab={activeTab}
                key={tab}
                tab={tab}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </ol>
        <div className="tab-content">
          {children.map((child) => {
            if (child.props.tab !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

PurchasePaymentMethodTabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  onSelectPaymentMethod: PropTypes.func.isRequired
};

export default PurchasePaymentMethodTabs;
