import React from "react";
import PropTypes from "prop-types";
import { errorReasonEnum } from "../redux/modules/error";
import { connect } from "react-redux";
import { resetErrorMessage } from "../redux/modules/error";
import PaymentExpired from "../components/PaymentExpired";
import PaymentError from "../components/PaymentError";
import PurchaseConsent from "./PurchaseConsent";
import analyticsClient from "../utils/analyticsPlatformClient";
import { getError, getErrorReason } from "../redux/modules/error";
import { getSessionToken } from "../redux/modules/session";
import { withNavigation } from "./NavigationProvider";

// This is for handling payment errors - for client errors, see ErrorBoundary
class ErrorHandler extends React.Component {
  onAckError = () => {
    this.props.resetErrorMessage();
    this.props.navigate("/");
  };

  render() {
    let { errorReason } = this.props;
    analyticsClient.sendEvent("sessionErrorEvent", this.props.sessionToken, {
      errorMessage: this.props.error,
      errorCode: this.props.errorReason
    });

    if (errorReason === errorReasonEnum.AUTH_ERROR) {
      return <PaymentExpired />;
    }

    if (errorReason === errorReasonEnum.VELOCITY_ERROR) {
      return <PaymentError errorCode="velocity-error" onAckError={this.onAckError} />;
    }

    if (errorReason === errorReasonEnum.PAYMENT_METHOD_NOT_ALLOWED) {
      return <PaymentError errorCode="payment-method-not-allowed-error" onAckError={this.onAckError} />;
    }

    if (errorReason === errorReasonEnum.CONSENT_REQUIRED) {
      return <PurchaseConsent />;
    }

    if (!errorReasonEnum.isDefined(errorReason)) {
      return <PaymentError onAckError={this.onAckError} />;
    }

    return <div />;
  }
}

ErrorHandler.propTypes = {
  resetErrorMessage: PropTypes.func.isRequired,
  errorReason: PropTypes.number,
  error: PropTypes.string,
  sessionToken: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  error: getError(state), // Pass the error message
  errorReason: getErrorReason(state), // Pass the error reason (Hermes error code)
  sessionToken: getSessionToken(state)
});

export default connect(mapStateToProps, { resetErrorMessage })(withNavigation(ErrorHandler));
