import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { formatNumberWithCommas } from "../../utils/formatters";
import "./PurchaseLeftPanel.css";
import titleImg from "../../static/img/kr/riotgames_title.jpg";
import riotGamesLogo from "../../static/img/kr/riotgames_logo.png";
import batteryLogo from "../../static/img/kr/battery_logo.png";
import giftLogo from "../../static/img/kr/gift_logo.png";

const PurchaseLeftPanel = ({
  isGift,
  purchaseContext,
  selectedVirtualCurrencyTotalAmount,
  onEmailChange,
  emailOverride,
  virtualCurrencyCode
}) => (
  <div className="left-panel">
    <div className="content">
      <img src={titleImg} alt="RIOT GAMES KOREA" />
      <div>
        <div className="logoRow">
          <div className="logoColumn">
            <img
              className="battery"
              src={isGift ? giftLogo : batteryLogo}
              alt={
                virtualCurrencyCode && (
                  <FormattedMessage id={`vc_code_${virtualCurrencyCode}`} defaultMessage={virtualCurrencyCode} />
                )
              }
            />
          </div>
          <div className="logoColumn logoText">
            {virtualCurrencyCode && (
              <FormattedMessage id={`vc_code_${virtualCurrencyCode}`} defaultMessage={virtualCurrencyCode} />
            )}
            &nbsp;
            <FormattedMessage id={isGift ? "kr_gifting" : "kr_charging"}>
              {(text) => <span className="logoTextRed">{text}</span>}
            </FormattedMessage>
          </div>
        </div>
      </div>
      <ul>
        {isGift && purchaseContext != null && purchaseContext.gifteeSummonerName != null && (
          <li className="gift">
            <span className="gift">
              {purchaseContext.gifteeSummonerName}
              {virtualCurrencyCode && (
                <FormattedMessage
                  id="kr_purchase_giftee_summoner_name"
                  defaultMessage="님 환영합니다."
                  values={{
                    vc_name: (
                      <FormattedMessage id={`vc_code_${virtualCurrencyCode}`} defaultMessage={virtualCurrencyCode} />
                    )
                  }}
                />
              )}
            </span>
          </li>
        )}
        {purchaseContext && purchaseContext.userEmail && onEmailChange && (
          <li className="email">
            <FormattedMessage id="kr_purchase_email" defaultMessage="결제 알림 메일" />
            <div className="address">
              {/*<PurchaseChangeEmail*/}
              {/*purchaseContext={purchaseContext}*/}
              {/*onEmailChange={onEmailChange}*/}
              {/*emailOverride={emailOverride}*/}
              {/*/>*/}
            </div>
            <span className="address">{emailOverride || purchaseContext.userEmail}</span>
          </li>
        )}
        {!isNaN(selectedVirtualCurrencyTotalAmount) && (
          <li className="rp">
            {isGift ? (
              virtualCurrencyCode && (
                <FormattedMessage
                  id="kr_gift_amount"
                  defaultMessage="{vc_name} 선물"
                  values={{
                    vc_name: (
                      <FormattedMessage id={`vc_code_${virtualCurrencyCode}`} defaultMessage={virtualCurrencyCode} />
                    )
                  }}
                />
              )
            ) : (
              <FormattedMessage id="kr_purchase_rp_amount" />
            )}
            <div>
              <span className="amount">+{formatNumberWithCommas(selectedVirtualCurrencyTotalAmount)}</span>
              <span className="currency">
                {virtualCurrencyCode && (
                  <FormattedMessage id={`vc_code_${virtualCurrencyCode}`} defaultMessage={virtualCurrencyCode} />
                )}
              </span>
            </div>
          </li>
        )}
      </ul>
      <img className="rg-logo" src={riotGamesLogo} alt="Riot Games" width="100px" />
    </div>
  </div>
);

PurchaseLeftPanel.propTypes = {
  isGift: PropTypes.bool.isRequired,
  purchaseContext: PropTypes.object,
  selectedVirtualCurrencyTotalAmount: PropTypes.number,
  onEmailChange: PropTypes.func,
  emailOverride: PropTypes.string,
  virtualCurrencyCode: PropTypes.string
};

export default PurchaseLeftPanel;
