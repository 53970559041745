import PropTypes from "prop-types";
import React from "react";

import { isDirectPurchase } from "../redux/modules/paymentOptions";
import PaymentOptionsTos from "./PaymentOptionsTos";

class PaymentOptionsFooter extends React.Component {
  render() {
    return (
      <div className="payment-options-footer">
        <div id="payment-options-footer-tos">
          <PaymentOptionsTos
            virtualCurrency={this.props.virtualCurrency}
            showTaxDisclaimer={this.props.showTaxDisclaimer}
            isDirectPurchase={isDirectPurchase(this.props.isDirectPurchase)}
          />
        </div>
      </div>
    );
  }
}

PaymentOptionsFooter.propTypes = {
  virtualCurrency: PropTypes.string.isRequired,
  showTaxDisclaimer: PropTypes.bool,
  isDirectPurchase: PropTypes.bool
};

export default PaymentOptionsFooter;
