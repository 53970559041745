import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { getSessionStoreCode, startNewPurchase } from "../../redux/modules/session";
import { getSessionUseApiV2 } from "../../redux/modules/session";
import PurchaseConsentConfirmed from "./PurchaseConsentConfirmed";
import PurchaseResultSuccess from "./PurchaseResultSuccess";
import PurchaseResultFailed from "./PurchaseResultFailed";
import PurchaseResultPending from "./PurchaseResultPending";
import PurchaseLeftPanel from "./PurchaseLeftPanel";

import "./ResultPage.css";
import { getPurchaseCurrency } from "../../redux/modules/paymentOptions";
import { withNavigation } from "../../containers/NavigationProvider";

class ResultPage extends React.Component {
  startNewPurchase = () => {
    this.props.startNewPurchase();
    this.props.navigate("/");
  };

  getResultContainer = () => {
    const { purchaseDetails, resultCode, useApiV2 } = this.props;

    const errorOrPendingDetails = sessionStorage.purchaseErrorOrPendingDetails
      ? JSON.parse(sessionStorage.purchaseErrorOrPendingDetails)
      : "";

    const successDetails = sessionStorage.successPurchaseDetails
      ? JSON.parse(sessionStorage.successPurchaseDetails)
      : "";

    if (resultCode === "success") {
      return (
        <PurchaseResultSuccess
          paymentDate={successDetails.paymentDate}
          paymentMethod={successDetails.paymentMethod}
          virtualAmount={Number(successDetails.virtualAmount)}
          currencyAmount={Number(successDetails.currencyAmount) / 100}
          startNewPurchase={this.startNewPurchase}
          useApiV2={useApiV2}
          virtualCurrencyCode={this.props.virtualCurrencyCode}
          storeCode={this.props.storeCode}
        />
      );
    }

    if (resultCode === "failed") {
      return (
        <PurchaseResultFailed
          errorCode={errorOrPendingDetails ? errorOrPendingDetails.errorCode : purchaseDetails.errorCode}
          errorMessage={errorOrPendingDetails ? errorOrPendingDetails.errorMessage : purchaseDetails.errorMessage}
          virtualCurrencyCode={this.props.virtualCurrencyCode}
          storeCode={this.props.storeCode}
        />
      );
    }

    if (resultCode === "pending") {
      return (
        <PurchaseResultPending
          bankName={errorOrPendingDetails.bankName}
          accountNumber={errorOrPendingDetails.accountNumber}
          depositorName={errorOrPendingDetails.depositorName}
          expirationDate={errorOrPendingDetails.expirationDate}
          amount={errorOrPendingDetails.amount}
          virtualCurrencyCode={this.props.virtualCurrencyCode}
          storeCode={this.props.storeCode}
        />
      );
    }

    if (resultCode === "consent-confirmed") {
      return (
        <PurchaseConsentConfirmed
          formattedDate={purchaseDetails.formattedDate}
          gotoPaymentOptionsHandler={purchaseDetails.gotoPaymentOptionsHandler}
        />
      );
    }
  };

  render() {
    return (
      <div>
        <Helmet>
          <body className="kr" />
        </Helmet>
        <div className="kr-purchase-result">
          <PurchaseLeftPanel isGift={this.props.isGift} virtualCurrencyCode={this.props.virtualCurrencyCode} />
          {this.getResultContainer()}
        </div>
      </div>
    );
  }
}

ResultPage.propTypes = {
  isGift: PropTypes.bool.isRequired,
  purchaseDetails: PropTypes.object.isRequired,
  resultCode: PropTypes.string.isRequired,
  startNewPurchase: PropTypes.func.isRequired,
  useApiV2: PropTypes.bool.isRequired,
  virtualCurrencyCode: PropTypes.string.isRequired,
  storeCode: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  useApiV2: getSessionUseApiV2(state),
  virtualCurrencyCode: getPurchaseCurrency(state) === undefined ? undefined : getPurchaseCurrency(state).toLowerCase(),
  storeCode: getSessionStoreCode(state)
});

export default connect(mapStateToProps, { startNewPurchase })(withNavigation(ResultPage));
