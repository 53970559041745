import PropTypes from "prop-types";
import React, { createContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { Provider, connect } from "react-redux";
import App from "./App";
import PaymentOptions from "./PaymentOptions";
import PrepaidMode from "./PrepaidMode";
import PaymentComplete from "./PaymentComplete";
import Start from "./Start";
import BundleSelection from "./v2/BundleSelection";
import PaymentMethodSelection from "./v2/PaymentMethodSelection";
import PaymentCancelled from "../components/PaymentCancelled";
import ExternalWindowOpener from "../containers/ExternalWindowOpener";
import KrPaymentCancelled from "../components/kr/KrPaymentCancelled";
import PaymentFailed from "./PaymentFailed";
import InvalidGiftee from "./InvalidGiftee";
import PurchaseResultRedirect from "../components/kr/PurchaseResultRedirect";
import ErrorBoundary from "../containers/ErrorBoundary";
import { getSessionIsNewFlow, getSessionToken } from "../redux/modules/session";
import analyticsClient from "../utils/analyticsPlatformClient";
import { NavigationProvider } from "./NavigationProvider";


export const RootContext = createContext();

class Root extends React.Component {
  componentDidMount() {
    // This is called before the user leaves the page
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  }

  render() {
    const { store, locale, messages, apiClient, isNewFlow } = this.props;
    return (
      <RootContext.Provider value={{ apiClient }}>
        <IntlProvider locale={locale} messages={messages} textComponent="span">
          <Provider store={store}>
            <ErrorBoundary>
              <Router>
                <NavigationProvider>
                  <App>
                    <Routes>
                      <Route exact path="/" element={isNewFlow ? <BundleSelection /> : <PaymentOptions />} />
                      <Route path="/prepaid" element={<PrepaidMode />} />
                      <Route path="/checkout-success" element={<PaymentComplete />} />
                      <Route path="/checkout-cancelled" element={<PaymentCancelled />} />
                      <Route path="/checkout-failed" element={<PaymentFailed />} />
                      <Route path="/invalid-giftee" element={<InvalidGiftee />} />
                      <Route path="/start" element={<Start />} />
                      <Route path="/kr/checkout-success" element={<PurchaseResultRedirect result="success" />} />
                      <Route path="/kr/checkout-failed" element={<PurchaseResultRedirect result="failed" />} />
                      <Route path="/kr/checkout-pending" element={<PurchaseResultRedirect result="pending" />} />
                      <Route path="/kr/checkout-cancelled" element={<KrPaymentCancelled />} />
                      <Route path="/external-window-open" element={<ExternalWindowOpener />} />
                      <Route path="/payment-method-selection" element={<PaymentMethodSelection />} />
                    </Routes>
                  </App>
                </NavigationProvider>
              </Router>
            </ErrorBoundary>
          </Provider>
        </IntlProvider>
      </RootContext.Provider>
    );
  }

  handleBeforeUnload = (event) => {
    // Session token is not available when the user opens the page without starting a new session
    analyticsClient.sendEvent("sessionClosedEvent", this.props.sessionToken);
  };
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  apiClient: PropTypes.object.isRequired,
  // This could be absent if you open the page directly without a session token
  sessionToken: PropTypes.string
};

const mapStateToProps = (state) => ({
  isNewFlow: getSessionIsNewFlow(state),
  sessionToken: getSessionToken(state)
});

export default connect(mapStateToProps)(Root);
