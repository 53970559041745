import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import "./PurchasePricePointRow.css";
import PurchasePricePoint from "./PurchasePricePoint";

const PurchasePricePointRow = ({ activePricePointId, pricePoints, onPricePointSelect, validCurrencyAmounts }) => (
  <div className="price-point-row">
    <dl>
      <dt>
        <FormattedMessage id="kr_purchase_options" defaultMessage="결제 금액" />
      </dt>
    </dl>
    <dd>
      <ul>
        {pricePoints.map((pricePoint) => (
          <li key={pricePoint.id}>
            <PurchasePricePoint
              pricePoint={pricePoint}
              isActive={pricePoint.id === activePricePointId}
              isEnabled={!validCurrencyAmounts || validCurrencyAmounts.indexOf(pricePoint.realAmountCents) >= 0}
              onClick={onPricePointSelect}
            />
          </li>
        ))}
      </ul>
    </dd>
  </div>
);

PurchasePricePointRow.propTypes = {
  activePricePointId: PropTypes.string,
  pricePoints: PropTypes.array.isRequired,
  onPricePointSelect: PropTypes.func.isRequired,
  validCurrencyAmounts: PropTypes.array
};

export default PurchasePricePointRow;
