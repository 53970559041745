import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { getSessionStoreCode } from "../redux/modules/session";

const VirtualCurrencyAmount = ({ value, storeCode, intl }) => {
  // Only Valorant uses formatting
  if (storeCode !== "valorant") {
    return value;
  }

  // react-intl includes <FormattedNumber /> which wraps the native browser formatter,
  // but <FormattedNumberParts /> is only available in newer versions
  let formatter = new Intl.NumberFormat(intl.locale, { useGrouping: true });

  // for Spanish the ICU library defines the separator as a dot; however, we use a no-break space.
  if (/^es(-.+)?$/.test(intl.locale)) {
    return formatter
      .formatToParts(value)
      .map((p) => {
        if (p.type === "group") {
          return "\u00A0";
        }
        return p.value;
      })
      .join("");
  }

  return formatter.format(value);
};

VirtualCurrencyAmount.propTypes = {
  value: PropTypes.number.isRequired,
  storeCode: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  storeCode: getSessionStoreCode(state)
});

export default connect(mapStateToProps)(injectIntl(VirtualCurrencyAmount));
