import Rfc190Scope from "./rfc190Scope";

/**
 * Analytics class to send event data to the AP Collector.
 */
class AnalyticsPlatformClient {
  enabled;
  rfc190Scope;
  analyticsUrl;

  /**
   * Maps simple event names to their full schema identifiers.
   */
  static SCHEMA_MAP = {
    paymentMethodClickedEvent: "rpg__payment__paymentMethodClickedEvent__v1",
    paymentMethodSelectedEvent: "rpg__payment__paymentMethodSelectedEvent__v1",
    pricePointClickedEvent: "rpg__payment__pricePointClickedEvent__v1",
    pricePointSelectedEvent: "rpg__payment__pricePointSelectedEvent__v1",
    sessionClosedEvent: "rpg__payment__sessionClosedEvent__v1",
    sessionErrorEvent: "rpg__payment__sessionErrorEvent__v1"
  };

  /**
   * Constructs an Analytics Platform Client instance.
   */
  constructor() {
    this.enabled = process.env.REACT_APP_ANALYTICS_ENABLED === "true";
    this.rfc190Scope = Rfc190Scope.of(process.env.REACT_APP_RFC190_SCOPE || "a.b.c.d.e");
    this.analyticsUrl = process.env.REACT_APP_ANALYTICS_URL || "http://default.url";
  }

  /**
   * Gets the current timestamp in ISO 8601 format.
   *
   * @returns {string} - The current timestamp in UTC.
   */
  static getCurrentTimestamp() {
    return new Date().toISOString();
  }

  /**
   * Sends an event to the Analytics Platform.
   *
   * @param {string} schemaKey - The simple event name (must exist in SCHEMA_MAP).
   * @param {string} sessionId - The PMC session ID to associate with.
   * @param {Object} additionalData - Additional payload data to include in the event (optional).
   */
  sendEvent(schemaKey, sessionId, additionalData = null) {
    // Session ID is required to send an event, but it might be undefined if the page was loaded without one
    if (!this.enabled || !sessionId) {
      return;
    }

    // Allows us to pass undefined and nulls as additionalData without breaking the event
    if (!additionalData) {
      additionalData = {};
    }

    const schema = AnalyticsPlatformClient.SCHEMA_MAP[schemaKey];

    if (!schema) {
      console.error(
        `Invalid schema key: "${schemaKey}". Available keys:`,
        Object.keys(AnalyticsPlatformClient.SCHEMA_MAP)
      );
      return;
    }

    const payload = [
      {
        ...additionalData,
        // Adding after the spread operator to ensure that the additionalData can't overwrite these fields
        metadata: {
          schema: schema,
          scope: {
            environment: this.rfc190Scope.environment,
            datacenter: this.rfc190Scope.datacenter,
            deployment: this.rfc190Scope.deployment,
            product: this.rfc190Scope.product,
            component: this.rfc190Scope.component
          },
          timestamp: AnalyticsPlatformClient.getCurrentTimestamp()
        },
        sessionId: sessionId
      }
    ];

    fetch(this.analyticsUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
      keepalive: true
    })
      .then((response) => {
        if (!response.ok) {
          console.error(`Analytics request failed with status: ${response.status}`);
        }
      })
      .catch((error) => {
        console.error("Error sending analytics event:", error);
      });
  }
}

const analyticsClient = new AnalyticsPlatformClient();

export default analyticsClient;
