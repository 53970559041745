const availableLocales = new RegExp(
  [
    "^en_US|cs_CZ|de_DE|el_GR|es_AR|es_ES|",
    "es_MX|fr_FR|hu_HU|it_IT|ko_KR|",
    "id_ID|th_TH|vi_VN|zh_MY|zh_TW|",
    "pl_PL|pt_BR|ro_RO|ru_RU|tr_TR|ja_JP|ar_AE$"
  ].join("")
);

const queryLocaleMatch = new RegExp(/locale=([_A-Za-z]{5})/);

export const DEFAULT_LOCALE = "en-US";

export function getLocaleFromUrl() {
  const match = window.location.href.match(queryLocaleMatch);
  if (match && match.length && match.length === 2) {
    const queryLocale = match[1];
    if (availableLocales.test(queryLocale)) {
      return queryLocale;
    }
  }
  // If the locale in the URL is not present we don't want to specify default here
  return undefined;
}

export function getBcp47Locale() {
  const locale = getLocaleFromUrl();
  if (locale) {
    return getLocaleFromUrl().replace("_", "-");
  }
  return undefined;
}

const translations = {
  ["en-US"]: () => import("./en_US.json"), //eslint-disable-line
  ["cs-CZ"]: () => import("./cs_CZ.json"), //eslint-disable-line
  ["de-DE"]: () => import("./de_DE.json"), //eslint-disable-line
  ["el-GR"]: () => import("./el_GR.json"), //eslint-disable-line
  ["es-AR"]: () => import("./es_AR.json"), //eslint-disable-line
  ["es-ES"]: () => import("./es_ES.json"), //eslint-disable-line
  ["es-MX"]: () => import("./es_MX.json"), //eslint-disable-line
  ["fr-FR"]: () => import("./fr_FR.json"), //eslint-disable-line
  ["hu-HU"]: () => import("./hu_HU.json"), //eslint-disable-line
  ["it-IT"]: () => import("./it_IT.json"), //eslint-disable-line
  ["ko-KR"]: () => import("./ko_KR.json"), //eslint-disable-line
  ["pl-PL"]: () => import("./pl_PL.json"), //eslint-disable-line
  ["pt-BR"]: () => import("./pt_BR.json"), //eslint-disable-line
  ["ro-RO"]: () => import("./ro_RO.json"), //eslint-disable-line
  ["ru-RU"]: () => import("./ru_RU.json"), //eslint-disable-line
  ["tr-TR"]: () => import("./tr_TR.json"), //eslint-disable-line
  ["ja-JP"]: () => import("./ja_JP.json"), //eslint-disable-line
  ["ar-AE"]: () => import("./ar_AE.json"), //eslint-disable-line
  ["id-ID"]: () => import("./id_ID.json"), //eslint-disable-line
  ["th-TH"]: () => import("./th_TH.json"), //eslint-disable-line
  ["vi-VN"]: () => import("./vi_VN.json"), //eslint-disable-line
  ["zh-MY"]: () => import("./zh_MY.json"), //eslint-disable-line
  ["zh-TW"]: () => import("./zh_TW.json") //eslint-disable-line
};

export function loadTranslations(locale) {
  return translations[locale]();
}
