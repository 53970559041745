import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import Currency from "./Currency";
import classNames from "classnames";
import analyticsClient from "../utils/analyticsPlatformClient";
import VirtualCurrencyAmount from "./VirtualCurrencyAmount";

class PricePointCard extends React.Component {
  onCardClick = () => {
    analyticsClient.sendEvent("pricePointClickedEvent", this.props.sessionToken, {
      position: this.props.position,
      recommended: this.props.isRecommended,
      realAmountCents: this.props.amount,
      realAmountCurrency: this.props.currency,
      virtualCurrency: {
        virtualAmount: this.props.vc,
        virtualBonusAmount: this.props.vcb,
        virtualCurrencyCode: this.props.virtualCurrencyCode
      }
    });

    if (this.props.storeCode === "2xko") {
      // For lion, this sets the selected price point for the continue button
      this.props.onPricePointClick(this.props.id);
      return;
    }

    this.props.attemptStartPurchase();

    if (!this.props.canStartPurchase) {
      // Set the attempted price point here so it only gets called when players haven't checked the EU checkbox
      this.props.setAttemptedPurchasePricePoint(this.props.id);
      return;
    }

    this.props.onPricePointClick(this.props.id);
  };

  render() {
    const { currency, amount, hasDoubleBonusVc, vc, vcb, virtualCurrencyName, virtualCurrencyCode, isNewFlow } =
      this.props;

    let vcTotal = vc + vcb;
    let price = <Currency value={amount / 100} currency={currency} />;

    return (
      <div
        className={classNames("price-point-card", {
          "attempted-purchase": this.props.isAttemptedPurchasePricePoint && !this.props.canStartPurchase,
          recommended: this.props.isRecommended,
          selected: this.props.isSelected
        })}
        onClick={this.onCardClick}
      >
        {this.props.isRecommended && (
          <div className="recommended-banner">
            {this.props.virtualCurrencyCode === "KP" ? (
              <FormattedMessage id="recommended" defaultMessage="Recommended" />
            ) : (
              <FormattedMessage
                id="just_enough_vc"
                defaultMessage="Just Enough {vc}"
                values={{ vc: virtualCurrencyCode }}
              />
            )}
          </div>
        )}
        <div className="price text-primary">{price}</div>
        <div className="vc">
          <VirtualCurrencyAmount value={vc} />{" "}
          <FormattedMessage id={`vc_name_${virtualCurrencyCode.toLowerCase()}`} defaultMessage={virtualCurrencyName} />
        </div>
        <div className="vcb text-primary">
          +{" "}
          {vcb !== 0 && hasDoubleBonusVc ? (
            <>
              <span style={{ textDecoration: "line-through" }}>
                <VirtualCurrencyAmount value={Math.round(vcb / 2)} />
              </span>{" "}
              <span style={{ textShadow: "1px 0px", letterSpacing: "1px", fontWeight: "bolder" }}>
                <VirtualCurrencyAmount value={vcb} />
              </span>
            </>
          ) : (
            <VirtualCurrencyAmount value={vcb} />
          )}{" "}
          <FormattedMessage
            id={`vc_bonus_${virtualCurrencyCode.toLowerCase()}`}
            defaultMessage={`Bonus ${virtualCurrencyName}`}
          />
        </div>
        <div className="vc-total">
          {isNewFlow ? <span className="vc-icon" /> : null}
          <VirtualCurrencyAmount value={vcTotal} />{" "}
          <FormattedMessage id={`vc_name_${virtualCurrencyCode.toLowerCase()}`} defaultMessage={virtualCurrencyName} />
        </div>
        <div className="vc-plus-vcb">
          <span className="vcb-vc-only">
            {vcb > 0 && (
              <>
                <VirtualCurrencyAmount value={vc} />{" "}
                <FormattedMessage
                  id={`vc_name_${virtualCurrencyCode.toLowerCase()}`}
                  defaultMessage={virtualCurrencyName}
                />
              </>
            )}
          </span>{" "}
          + <VirtualCurrencyAmount value={vcb} /> <FormattedMessage id="bonus" defaultMessage="bonus" />
        </div>
        <button className="btn btn-primary fist-bump-sm">
          <span className="btn-title-vc-total">
            <VirtualCurrencyAmount value={vcTotal} />
          </span>
          <span className="btn-title-price">{price}</span>
          {isNewFlow ? <span className="currency">{currency}</span> : null}
        </button>
      </div>
    );
  }
}

PricePointCard.propTypes = {
  attemptStartPurchase: PropTypes.func.isRequired,
  setAttemptedPurchasePricePoint: PropTypes.func.isRequired,
  isAttemptedPurchasePricePoint: PropTypes.bool.isRequired,
  canStartPurchase: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  hasDoubleBonusVc: PropTypes.bool.isRequired,
  vc: PropTypes.number.isRequired,
  vcb: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  onPricePointClick: PropTypes.func.isRequired,
  virtualCurrencyName: PropTypes.string.isRequired,
  virtualCurrencyCode: PropTypes.string.isRequired,
  isRecommended: PropTypes.bool,
  isSelected: PropTypes.bool,
  storeCode: PropTypes.string.isRequired,
  isNewFlow: PropTypes.bool,
  position: PropTypes.number.isRequired
};

export default PricePointCard;
