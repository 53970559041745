class AbExperiment {
  constructor(experiment) {
    this.storageKey = experiment.sessionStorageKey;
    this.envVar = experiment.envVar;
  }

  getTargetCohorts() {
    try {
      return process.env[this.envVar] ? JSON.parse(process.env[this.envVar]) : [];
    } catch (error) {
      return [];
    }
  }

  getActiveCohort() {
    return sessionStorage.getItem(this.storageKey);
  }

  persistCohortInStorage(cohort) {
    sessionStorage.setItem(this.storageKey, cohort);
  }
}

const activeExperiments = Object.freeze({
  contextAwareness: {
    envVar: "REACT_APP_CONTEXT_AWARENESS_EXPERIMENT",
    sessionStorageKey: "contextAwarenessCohort"
  },
  bundleCta: {
    envVar: "REACT_APP_BUNDLE_CTA_EXPERIMENT",
    sessionStorageKey: "bundleCtaCohort"
  },
  paymentMethodScroll: {
    envVar: "REACT_APP_PAYMENT_METHOD_SCROLL_EXPERIMENT",
    sessionStorageKey: "paymentMethodScrollCohort"
  }
});

export const ContextAwarenessExperiment = new AbExperiment(activeExperiments.contextAwareness);
export const BundleCtaExperiment = new AbExperiment(activeExperiments.bundleCta);
export const PaymentMethodScrollExperiment = new AbExperiment(activeExperiments.paymentMethodScroll);
