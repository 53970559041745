import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import request from "superagent";
import ModalDialog from "./ModalDialog";
import * as Sentry from "@sentry/browser";

const MODAL_CONTENTS_FILE_MISSING_ERROR_MESSAGE =
  "Document has not been localized for your language. " +
  "Please contact player support for full language terms and conditions.";

class ModalDialogLink extends React.Component {
  state = {
    modalOpen: false,
    title: <span />,
    modalContents: ""
  };

  onOpenModal = () => {
    this.setState({ modalOpen: true });
  };

  onCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  onShowModal = () => {
    const { intl } = this.props;

    this.loadTermsIfNeeded(this.props.modalContentsFileName, intl.locale);
    this.onOpenModal();
  };

  loadTermsIfNeeded = (name, locale) => {
    if (this.state.modalContents === "") {
      request.get(`/text/${locale}/${name}.txt`).end((err, res) => {
        if (err) {
          // Ignore
        } else {
          // This is a workaround for the cases where we don't have a file, the server returns
          // the index page instead of HTTP 404 in this case, this is by design to make React routing work.
          // We can detect when the document doesn't exist by checking its mime type.
          // Currently all legal documents are text files with mime type of 'text/plain'.
          if (res.type !== "text/plain") {
            Sentry.withScope((scope) => {
              scope.setLevel("warning");
              Sentry.captureMessage(`Missing modal contents file '/text/${locale}/${name}.txt'`);
            });
            this.setState({
              modalContents: MODAL_CONTENTS_FILE_MISSING_ERROR_MESSAGE
            });
          } else {
            let modalText = res.text;
            if (this.props.placeholders) {
              Object.keys(this.props.placeholders).forEach((key) => {
                const placeholder = `{${key}}`;
                const value = this.props.placeholders[key];
                modalText = modalText.replace(new RegExp(placeholder, "g"), value);
              });
            }
            this.setState({ modalContents: modalText });
          }
        }
      });
    }
  };

  render() {
    const title = (
      <FormattedMessage id={this.props.modalTitleLocId} defaultMessage={this.props.modalTitleDefaultMessage} />
    );

    return (
      <span>
        <a href="#/" onClick={this.onShowModal} className="link text-highlight">
          <FormattedMessage id={this.props.linkTextLocId} defaultMessage={this.props.linkTextDefaultMessage} />
        </a>

        <ModalDialog
          contentLabel="Modal dialog"
          open={this.state.modalOpen}
          title={title}
          content={this.state.modalContents}
          onRequestClose={this.onCloseModal}
        />
      </span>
    );
  }
}

ModalDialogLink.propTypes = {
  linkTextLocId: PropTypes.string.isRequired,
  linkTextDefaultMessage: PropTypes.string.isRequired,
  modalTitleLocId: PropTypes.string.isRequired,
  modalTitleDefaultMessage: PropTypes.string.isRequired,
  modalContentsFileName: PropTypes.string.isRequired,
  placeholders: PropTypes.object
};

export default injectIntl(ModalDialogLink);
