import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import queryString from "query-string";
import {
  getGlobalCssTheme,
  getSessionCurrentPurchaseId,
  getSessionToken,
  getSessionInstance
} from "../redux/modules/session";
import isExternalBrowserURL from "../utils/isExternalBrowserURL";
import { withNavigation } from "./NavigationProvider";

class ExternalWindowOpener extends React.Component {
  componentDidMount() {
    const query = queryString.parse(this.props.location.search);

    // 1. Gateway will redirect to this page in order to obtain a session token.
    if (isExternalBrowserURL(query.url) && this.props.sessionToken && !this.props.location.hash) {
      // 2. it will automatically open a new window with session token attached in url
      let location =
        this.props.location.pathname +
        "?url=" +
        encodeURIComponent(query.url) +
        "&i=" +
        this.props.instance +
        "&pid=" +
        this.props.currentPurchaseId +
        "&t=" +
        this.props.theme +
        "&locale=" +
        sessionStorage.getItem("locale") +
        "#s=" +
        this.props.sessionToken;

      window.open(location);
      return;
    }

    // 3. When a session token is in url, it was opened via a popup and it needs to be persisted
    // to local storage and redirect. After this call, current browser instance will have a session necessary
    // to display results on the success page.
    if (isExternalBrowserURL(query.url) && this.props.location.hash) {
      sessionStorage.setItem("sessionToken", this.props.location.hash.replace("#s=", ""));
      sessionStorage.setItem("useApiV2", true);
      sessionStorage.setItem("instance", query.i);
      sessionStorage.setItem("currentPurchaseId", query.pid);
      sessionStorage.setItem("cssTheme", query.t);
      sessionStorage.setItem("locale", query.locale);

      window.location = decodeURIComponent(query.url);
    }
  }

  render() {
    return (
      <div>
        <div className="container loading">
          <div className="loading-spinner" />
        </div>
        <div>
          <FormattedMessage
            id="external_window_warning"
            defaultMessage="If you see this message and have not made a purchase, please minimize the game to continue in browser."
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionToken: getSessionToken(state),
  currentPurchaseId: getSessionCurrentPurchaseId(state),
  instance: getSessionInstance(state),
  theme: getGlobalCssTheme(state)
});

export default connect(mapStateToProps)(withNavigation(ExternalWindowOpener));
