import React from "react";
import PropTypes from "prop-types";

import { injectIntl, FormattedMessage } from "react-intl";

import "./PurchaseLegalRow.css";
import PurchaseLegalPricePopup from "./PurchaseLegalPricePopup";

class PurchaseLegalRow extends React.Component {
  state = {
    pricePopupOpen: false
  };

  onPricePopupClose = () => {
    this.setState({ pricePopupOpen: false });
  };

  onPricePopupOpen = () => {
    this.setState({ pricePopupOpen: true });
    this.props.onConfirmPurchase();
  };

  onConfirmPurchaseChange = () => {
    if (!this.props.purchaseConfirmed) {
      this.setState({ pricePopupOpen: true });
    }

    this.props.onConfirmPurchaseChange();
  };

  initLegalNoticeContent = (paymentMethod, virtualCurrencyCode) => {
    if (paymentMethod === "pay1q-culture") {
      return (
        <ul>
          <li>컬쳐랜드 가입문의, 컬쳐캐시 충전, 사용내역은 홈페이지 혹은 고객센터를 통해 확인 가능합니다.</li>
          <li>
            컬쳐랜드 홈페이지 :&nbsp;
            <a href="http://www.cultureland.co.kr" target="_blank" rel="noopener noreferrer">
              <ins>www.cultureland.co.kr</ins>
            </a>
            / 고객센터 : 1577-2111
          </li>
        </ul>
      );
    } else if (paymentMethod === "pay1q-teencash") {
      return (
        <ul>
          <li>틴캐시 가입문의, 틴캐시 충전, 사용내역은 홈페이지 혹은 고객센터를 통해 확인 가능합니다.</li>
          <li>
            틴캐시 홈페이지 :&nbsp;
            <a href="http://www.teencash.co.kr" target="_blank" rel="noopener noreferrer">
              <ins>www.teencash.co.kr</ins>
            </a>
            / 고객센터 : 1666-3009
          </li>
        </ul>
      );
    } else if (paymentMethod === "pay1q-happymoney") {
      return (
        <ul>
          <li>해피머니 가입문의, 해피캐시 충전, 사용내역은 홈페이지 혹은 고객센터를 통해 확인 가능합니다.</li>
          <li>
            해피머니 홈페이지 :&nbsp;
            <a href="http://www.happymoney.co.kr" target="_blank" rel="noopener noreferrer">
              <ins>www.happymoney.co.kr</ins>
            </a>
            / 고객센터 : 1588-5245
          </li>
        </ul>
      );
    } else if (paymentMethod === "pay1q-book") {
      return (
        <ul>
          <li>북앤라이프 가입문의, 북앤라이프 캐시 충전, 사용내역은 홈페이지 혹은 고객센터를 통해 확인 가능합니다.</li>
          <li>
            북앤라이프 홈페이지 :&nbsp;
            <a href="http://www.booknlife.com" target="_blank" rel="noopener noreferrer">
              <ins>www.booknlife.com</ins>
            </a>
            / 고객센터 : 1544-5111
          </li>
        </ul>
      );
    } else if (paymentMethod === "pay1q-smartculture") {
      return (
        <ul>
          <li>스마트문상으로 결제 후 남은 금액은 컬쳐랜드 잔액합치기 페이지에서 잔액 통합이 가능합니다.</li>
          <li>
            컬쳐랜드 홈페이지 :&nbsp;
            <a href="http://www.cultureland.co.kr" target="_blank" rel="noopener noreferrer">
              <ins>www.cultureland.co.kr</ins>
            </a>
            / 고객센터 : 1577-2111
          </li>
        </ul>
      );
    } else if (paymentMethod === "cybercvs") {
      return (
        <ul>
          <li>
            편의점에서 구매하신 금액에 해당하는&nbsp;
            <FormattedMessage id={`vc_code_${virtualCurrencyCode}`} defaultMessage={virtualCurrencyCode} />만 자동
            충전됩니다.
          </li>
          <li>핀번호는 알파벳 대문자(1자리)+숫자(11자리)로 구성되어 있습니다.</li>
          <li>
            갤럭시아머니트리 홈페이지 :&nbsp;
            <a href="https://www.cashgate.co.kr" target="_blank" rel="noopener noreferrer">
              <ins>www.cashgate.co.kr</ins>
            </a>
            &nbsp;고객센터 연락처 : 1566-0123
          </li>
        </ul>
      );
    } else if (paymentMethod === "pay1q-cyberposa") {
      return (
        <ul>
          <li>
            편의점에서 구매하신 금액에 해당하는&nbsp;
            <FormattedMessage id={`vc_code_${virtualCurrencyCode}`} defaultMessage={virtualCurrencyCode} />만 자동
            충전됩니다.
          </li>
          <li>핀번호는 알파벳 대문자(1자리)+숫자(11자리)로 구성되어 있습니다.</li>
          <li>
            갤럭시아머니트리 홈페이지 :&nbsp;
            <a href="https://www.cashgate.co.kr:8443" target="_blank" rel="noopener noreferrer">
              <ins>www.cashgate.co.kr</ins>
            </a>
            &nbsp;고객센터 연락처 : 1566-0123
          </li>
        </ul>
      );
    } else if (paymentMethod === "toss-creditcard") {
      return (
        <ul>
          <li>해당 카드에 따라 VISA 안심클릭,ISP,일반결제로 진행됩니다.</li>
          <li>이용요금은 '토스페이먼츠 주식회사'로 표시됩니다.</li>
          <li>타인의 카드를 동의 없이 무단으로 사용하면 형사처벌 받을 수 있습니다.</li>
        </ul>
      );
    } else if (paymentMethod === "toss-brandpaycard") {
      return (
        <ul>
          <li>등록해둔 신용카드로 간편하게 결제 가능합니다.</li>
          <li>결제 금액 제한은 각 카드사 정책을 따릅니다.</li>
          <li>
            등록해둔 결제 수단의 관리는 다음&nbsp;
            <a href="#/" onClick={this.props.onPreferenceOpen}>
              링크
            </a>
            에서 가능합니다.
          </li>
        </ul>
      );
    } else if (paymentMethod === "toss-prefbrandpaycard") {
      return (
        <ul>
          <li>등록해둔 신용카드로 간편하게 결제 가능합니다.</li>
          <li>결제 금액 제한은 각 카드사 정책을 따릅니다.</li>
          <li>
            등록해둔 결제 수단의 관리는 다음&nbsp;
            <a href="#/" onClick={this.props.onPreferenceOpen}>
              링크
            </a>
            에서 가능합니다.
          </li>
        </ul>
      );
    } else if (paymentMethod === "toss-brandpaybank") {
      return (
        <ul>
          <li>등록해둔 계좌로 간편하게 결제 가능합니다.</li>
          <li>결제 금액은 즉시 계좌에서 이체됩니다.</li>
          <li>
            등록해둔 결제 수단의 관리는 다음&nbsp;
            <a href="#/" onClick={this.props.onPreferenceOpen}>
              링크
            </a>
            에서 가능합니다.
          </li>
        </ul>
      );
    } else if (paymentMethod === "toss-prefbrandpaybank") {
      return (
        <ul>
          <li>등록해둔 계좌로 간편하게 결제 가능합니다.</li>
          <li>결제 금액은 즉시 계좌에서 이체됩니다.</li>
          <li>
            등록해둔 결제 수단의 관리는 다음&nbsp;
            <a href="#/" onClick={this.props.onPreferenceOpen}>
              링크
            </a>
            에서 가능합니다.
          </li>
        </ul>
      );
    } else if (paymentMethod === "toss-creditcard-mac") {
      return (
        <ul>
          <li>해당 카드에 따라 VISA 안심클릭,ISP,일반결제로 진행됩니다.</li>
          <li>이용요금은 '토스페이먼츠 주식회사'로 표시됩니다.</li>
          <li>타인의 카드를 동의 없이 무단으로 사용하면 형사처벌 받을 수 있습니다.</li>
        </ul>
      );
    } else if (paymentMethod === "inicis-creditcard") {
      return (
        <ul>
          <li>해당 카드에 따라 VISA 안심클릭,ISP,일반결제로 진행됩니다.</li>
          <li>이용요금은 '(주)케이지이니시스'로 표시됩니다.</li>
          <li>타인의 카드를 동의 없이 무단으로 사용하면 형사처벌 받을 수 있습니다.</li>
        </ul>
      );
    } else if (paymentMethod === "inicis-creditcard-mac") {
      return (
        <ul>
          <li>ISP 결제방식의 신용카드는 Mac에서 사용하실수 없습니다.</li>
          <li>이용요금은 '(주)케이지이니시스'로 표시됩니다.</li>
          <li>타인의 카드를 동의 없이 무단으로 사용하면 형사처벌 받을 수 있습니다.</li>
        </ul>
      );
    } else if (paymentMethod === "pay1q-impaymobile") {
      return (
        <ul>
          <li>결제 금액은 다음달 휴대폰요금에 합산 청구됩니다.</li>
          <li>휴대폰 가입자의 동의 없이 휴대폰 결제를 이용할 경우, 관련법에 의한 형사 처벌을 받을 수 있습니다.</li>
        </ul>
      );
    } else if (paymentMethod === "pay1q-toss") {
      return (
        <ul>
          <li>
            <FormattedMessage id={`vc_code_${virtualCurrencyCode}`} defaultMessage={virtualCurrencyCode} />
            충전은 Toss 앱이 설치되어 있어야만 결제 가능합니다.
          </li>
          <li>Toss서비스 이용을 위해서는 해당 서비스의 회원가입이 필요합니다.</li>
          <li>Toss는 휴대폰에서 간편하게 송금할 수 있는 (주)비바리퍼블리카의 서비스 입니다.</li>
        </ul>
      );
    } else if (paymentMethod === "toss-tosspay") {
      return (
        <ul>
          <li>
            <FormattedMessage id={`vc_code_${virtualCurrencyCode}`} defaultMessage={virtualCurrencyCode} />
            충전은 Toss 앱이 설치되어 있어야만 결제 가능합니다.
          </li>
          <li>Toss서비스 이용을 위해서는 해당 서비스의 회원가입이 필요합니다.</li>
          <li>Toss는 휴대폰에서 간편하게 송금할 수 있는 (주)비바리퍼블리카의 서비스 입니다.</li>
        </ul>
      );
    } else if (paymentMethod === "pay1q-kftc") {
      return (
        <ul>
          <li>실시간 계좌이체는 금융결제원의 BANKPAY를 이용합니다.</li>
          <li>결제 금액은 즉시 계좌에서 이체됩니다.</li>
        </ul>
      );
    } else if (paymentMethod === "pay1q-vacct") {
      return (
        <ul>
          <li>신청이 완료되면 송금할 계좌번호, 계좌주명, 유효기간이 안내됩니다.</li>
          <li>가상계좌는 1주일간만 사용되는 임시 계좌이며,이후에는 새로 계좌번호를 받으셔야 합니다.</li>
        </ul>
      );
    } else if (paymentMethod === "pay1q-payco") {
      return (
        <ul>
          <li>휴대폰과 카드 명의자가 동일해야 결제 가능하며, 결제금액 제한은 각 카드사 정책을 따릅니다.</li>
          <li>사용 가능한 결제 수단은 결제화면 하단에서 확인할 수 있습니다.</li>
          <li>PAYCO 관련 혜택은 PAYCO 결제화면 내 안내를 통해 확인해주시기 바랍니다.</li>
        </ul>
      );
    } else if (paymentMethod === "kakaopay") {
      return (
        <ul>
          <li>결제를 위해서는 카카오톡 또는 카카오페이 모바일 앱이 필요합니다.</li>
          <li>카카오페이 고객센터 : 1644-7405</li>
        </ul>
      );
    } else if (paymentMethod === "naverpay") {
      return (
        <ul>
          <li>주문 변경 시 카드사 혜택 및 할부 적용 여부는 해당 카드사 정책에 따라 변경될 수 있습니다.</li>
          <li>
            네이버페이는 네이버ID로 별도 앱 설치 없이 카드 또는 계좌 정보를 등록하여 비밀번호로 결제할 수 있는 간편결제
            서비스입니다.
          </li>
          <li>네이버페이 카드 간편결제는 카드사 별 무이자,청구할인 혜택을 받을 수 있습니다.</li>
        </ul>
      );
    } else {
      return <ul />;
    }
  };

  render() {
    const {
      intl,
      realAmountCents,
      purchaseConfirmed,
      selectedPaymentMethod,
      isWindows,
      virtualCurrencyCode,
      gameCode
    } = this.props;

    let legalNoticeContent;
    if (selectedPaymentMethod.name === "toss-creditcard" && !isWindows) {
      legalNoticeContent = this.initLegalNoticeContent("toss-creditcard-mac", virtualCurrencyCode);
    } else if (selectedPaymentMethod.name === "inicis-creditcard" && !isWindows) {
      legalNoticeContent = this.initLegalNoticeContent("inicis-creditcard-mac", virtualCurrencyCode);
    } else {
      legalNoticeContent = this.initLegalNoticeContent(selectedPaymentMethod.name, virtualCurrencyCode);
    }

    return (
      <div className="legal-row">
        <dl>
          <dt>
            <FormattedMessage
              id={"kr_purchase_legal_row_title"}
              defaultMessage="{game} {vc_legal_name} {policy} 동의 및 결제 전 주의사항"
              values={{
                vc_legal_name: intl.formatMessage({
                  id: `kr_vc_legal_${virtualCurrencyCode}`,
                  defaultMessage: `${virtualCurrencyCode}`
                }),
                game: intl.formatMessage({ id: `game_name_${gameCode}` }),
                policy: intl.formatMessage({ id: "policy" })
              }}
            />
          </dt>
        </dl>
        <dd style={{ position: "relative" }}>
          <div className="notice-box">
            {legalNoticeContent}
            <div className="controls">
              <div>
                <a href="#/" onClick={this.onPricePopupOpen}>
                  상품,가격 및 유효기간
                </a>
                <span>을 확인하였으며, </span>
                <a
                  href={intl.formatMessage({
                    id: `game_payment_disclaimer_link_${gameCode}`
                  })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  계약 관련 고지 사항
                </a>
                <span>과 </span>

                <a
                  href={intl.formatMessage({
                    id: `game_vc_policy_link_${gameCode}`
                  })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ id: `game_name_${gameCode}`, defaultMessage: gameCode }) +
                    " " +
                    intl.formatMessage({
                      id: `kr_vc_legal_${virtualCurrencyCode}`,
                      defaultMessage: virtualCurrencyCode
                    }) +
                    " " +
                    intl.formatMessage({ id: "policy", defaultMessage: "policy" })}
                </a>

                <span> 및 결제 진행에 동의합니다.</span>
              </div>
              <div className="checkbox">
                <input
                  type="checkbox"
                  id="agreeBtn"
                  checked={purchaseConfirmed}
                  onChange={this.onConfirmPurchaseChange}
                />
                <label htmlFor="agreeBtn" />
              </div>
            </div>
          </div>
          <PurchaseLegalPricePopup
            realAmountCents={realAmountCents}
            open={this.state.pricePopupOpen}
            onClose={this.onPricePopupClose}
            virtualCurrencyCode={virtualCurrencyCode}
          />
        </dd>
      </div>
    );
  }
}

PurchaseLegalRow.propTypes = {
  realAmountCents: PropTypes.number,
  onConfirmPurchaseChange: PropTypes.func.isRequired,
  onConfirmPurchase: PropTypes.func.isRequired,
  purchaseConfirmed: PropTypes.bool.isRequired,
  selectedPaymentMethod: PropTypes.object.isRequired,
  isWindows: PropTypes.bool.isRequired,
  virtualCurrencyCode: PropTypes.string.isRequired,
  gameCode: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  onPreferenceOpen: PropTypes.func.isRequired
};

export default injectIntl(PurchaseLegalRow);
