import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { getSessionStoreCode } from "../redux/modules/session";
import * as Sentry from "@sentry/browser";

const ReturnToStoreButton = ({ storeCode, buttonMessage, buttonMessageDefault }) => {
  const sendMessage = (w) => {
    if (w) {
      w.postMessage({ action: "closePaymentsWindow" }, "*");
    }
  };

  const closeWindow = () => {
    try {
      if (storeCode === "lor") {
        // LoR in-app browser injects a special function that allows us to close its window
        if (typeof window.closeWebPayments === "function") {
          console.log("close payment window function called");
          window.closeWebPayments();
        } else {
          console.log("Failed to call close payment window function");
          Sentry.captureMessage("LoR in-app browser function 'closeWebPayments' not found in window.");
        }
        return;
      }
      // In VALORANT we need to use a special API to close the window due to how the pyments window is embedded in the game client
      // The API should be explicitly exposed by the client, please contact the VALORANT team if you need to open more APIs
      // See https://riotgames.atlassian.net/browse/VSTO-1831
      if (storeCode === "valorant") {
        if (
          // Note: null is an object in JavaScript
          typeof window.ue === "object" &&
          window.ue !== null &&
          typeof window.ue.store === "object" &&
          window.ue.store !== null &&
          typeof window.ue.store.closewindow === "function"
        ) {
          // Unreal requires functions called from JavaScript to be all lowercase, so we need to call closewindow instead of closeWindow
          window.ue.store.closewindow();
        } else {
          Sentry.captureMessage("VALORANT in-app browser function 'closewindow' not found in window.");
        }
        return;
      }

      if (storeCode === "2xko") {
        if (typeof window.ue?.store?.closepaymentswindow === "function") {
          window.ue.store.closepaymentswindow();
        }
      }

      if (sendMessage(window.opener.opener) || sendMessage(window.opener)) {
        console.log("closePaymentsWindow message sent");
      }
    } catch (e) {
      console.log("Failed to send closePaymentsWindow message");
      console.log(e.message);
    }
  };

  return (
    <button className="btn btn-default" onClick={closeWindow}>
      <FormattedMessage id={buttonMessage} defaultMessage={buttonMessageDefault} />
    </button>
  );
};

ReturnToStoreButton.propTypes = {
  buttonMessage: PropTypes.string.isRequired,
  buttonMessageDefault: PropTypes.string.isRequired,
  storeCode: PropTypes.string
};

const mapStateToProps = (state) => ({
  storeCode: getSessionStoreCode(state)
});

export default connect(mapStateToProps)(ReturnToStoreButton);
