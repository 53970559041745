import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getPurchaseContext, isContextAwarenessAvailable } from "../../redux/modules/purchaseContext";
import {
  getMinVirtualAmount,
  getPaymentOptionsView,
  getSelectedPricePointId
} from "../../redux/modules/paymentOptions";
import Currency from "../Currency";
import { findPricePointForId, getPricePointAmount } from "../../utils/pricePoint";
import { FormattedMessage, injectIntl } from "react-intl";
import classNames from "classnames";

export const itemType = "item";
export const bundleType = "bundle";

class ContextSidebar extends React.Component {
  getGameContentMetadata = () => {
    try {
      const metadata = JSON.parse(this.props.purchaseContext?.gameContentMetadataJson);
      return metadata;
    } catch {
      return null;
    }
  };

  getCurrentBalance = () => this.props.purchaseContext?.virtualCurrencyWalletBalance || 0;
  getAmountNeeded = () => this.props.minVirtualAmount || 0;
  getPricePoint = () => findPricePointForId(this.props.paymentOptions, this.props.selectedPricePointId);

  getPricePointVcString = () => {
    const pricePoint = this.getPricePoint();
    if (!pricePoint) {
      return "";
    }

    return this.props.intl.formatMessage({
      id: `vc_code_${pricePoint.virtualCurrencyCode}`.toLowerCase(),
      defaultMessage: pricePoint.virtualCurrencyCode
    });
  };

  render() {
    // Don't render the sidebar if Context Awareness is not available
    if (!this.props.isContextAwarenessAvailable) {
      return null;
    }

    const isBundleType = this.props.type === bundleType;

    return (
      <div className="sidebar-container">
        <div className="content">
          {this.renderPurchaseContent(isBundleType)}
          {this.renderBalance(isBundleType)}
          {isBundleType && this.renderTotal()}
        </div>
      </div>
    );
  }

  renderPurchaseContent = (isBundleType) => {
    const item = isBundleType ? this.getRpBundleDetails() : this.getGameContentDetails();
    if (!item) {
      return null;
    }

    const headerId = isBundleType ? "buying" : "item_you_want";
    const headerDefault = isBundleType ? "Buying" : "Item you want";

    return (
      <div className="item-section">
        <div className="item-header">
          <FormattedMessage id={headerId} defaultMessage={headerDefault} />
        </div>
        <div className="item-row">
          <div className="item-details">
            <div className="item-name">{item.name}</div>
            {item.type && <div className="item-type">{item.type}</div>}
          </div>
          <div className={classNames("item-price", { rp: isBundleType })}>
            <div className="currency-icon"></div>
            <div className="price">{item.price}</div>
          </div>
        </div>
      </div>
    );
  };

  getGameContentDetails = () => {
    const metadata = this.getGameContentMetadata();
    if (!metadata) {
      return null;
    }

    const typeMap = {
      CHAMPION: <FormattedMessage id="champion" defaultMessage="Champion" />,
      CHAMPION_SKIN: <FormattedMessage id="champion_skin" defaultMessage="Champion Skin" />
    };

    return {
      name: metadata.name,
      price: `${this.getCurrentBalance() + this.getAmountNeeded()} ${this.getPricePointVcString()}`,
      type: typeMap[metadata.inventoryType] || ""
    };
  };

  getRpBundleDetails = () => {
    const pricePoint = this.getPricePoint();
    if (!pricePoint) {
      return null;
    }

    const amount = getPricePointAmount(pricePoint);
    const pricePointVcString = this.getPricePointVcString();

    return {
      name: (
        <>
          {amount} {pricePoint.virtualCurrencyCode} <FormattedMessage id="bundle" defaultMessage="Bundle" />
        </>
      ),
      price: `${amount} ${pricePointVcString}`
    };
  };

  renderBalance = (isBundleType) => {
    const pricePoint = this.getPricePoint();
    if (!pricePoint) {
      return null;
    }

    const newBalance = this.getCurrentBalance() + getPricePointAmount(pricePoint);

    const balanceRowId = isBundleType ? "new_balance" : "vc_needed";
    const balanceRowDefault = isBundleType ? "New Balance" : "{vc} Needed";
    const balanceRowAmount = isBundleType ? newBalance : this.getAmountNeeded();
    const pricePointVcString = this.getPricePointVcString();

    return (
      <div className="balance-section">
        <div className="balance-header">
          <FormattedMessage id="vc_balance" defaultMessage="{vc} Balance" values={{ vc: pricePointVcString }} />
        </div>
        <div className="balance-details">
          <div className="balance-row">
            <div className="label">
              <FormattedMessage id="current_balance" defaultMessage="Current Balance" />
            </div>
            <div className="value">
              <div className="currency-icon"></div>
              <div className="amount">{`${this.getCurrentBalance()} ${pricePointVcString}`}</div>
            </div>
          </div>
          <div className="balance-row alternative">
            <div className="label">
              <FormattedMessage
                id={balanceRowId}
                defaultMessage={balanceRowDefault}
                values={{
                  vc: pricePointVcString
                }}
              />
            </div>
            <div className="value">
              <div className="currency-icon"></div>
              <div className="amount">{`${balanceRowAmount} ${pricePointVcString}`}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderTotal = () => {
    const pricePoint = this.getPricePoint();
    if (!pricePoint) {
      return null;
    }

    return (
      <div className="total-section">
        <div className="total-container">
          <div className="total-label">
            <FormattedMessage id="total" defaultMessage="Total" />
          </div>
          <div className="total-price">
            <div className="price">
              <Currency value={pricePoint.realAmountCents / 100} currency={pricePoint.realCurrencyCode} />
            </div>
            <div className="currency">{pricePoint.realCurrencyCode}</div>
          </div>
        </div>
        <div className="footer-note">
          <FormattedMessage
            id="sidebar_disclaimer"
            defaultMessage="Your total might change depending on the payment method you choose."
          />
        </div>
      </div>
    );
  };
}

ContextSidebar.propTypes = {
  type: PropTypes.oneOf([itemType, bundleType]).isRequired,
  purchaseContext: PropTypes.object.isRequired,
  paymentOptions: PropTypes.array.isRequired,
  selectedPricePointId: PropTypes.number.isRequired,
  minVirtualAmount: PropTypes.number.isRequired,
  isContextAwarenessAvailable: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  paymentOptions: getPaymentOptionsView(state),
  purchaseContext: getPurchaseContext(state),
  selectedPricePointId: getSelectedPricePointId(state),
  minVirtualAmount: getMinVirtualAmount(state),
  isContextAwarenessAvailable: isContextAwarenessAvailable(state)
});

export default connect(mapStateToProps)(injectIntl(ContextSidebar));
