export function isCpfValid(cpf) {
  if (!cpf) {
    return false;
  }

  var invalid = [
    "111.111.111-11",
    "222.222.222-22",
    "333.333.333-33",
    "444.444.444-44",
    "555.555.555-55",
    "666.666.666-66",
    "777.777.777-77",
    "888.888.888-88",
    "999.999.999-99",
    "000.000.000-00"
  ];
  for (let i = 0; i < invalid.length; i++) {
    if (invalid[i] === cpf) {
      return false;
    }
  }

  cpf = cpf.replace("-", "");
  cpf = cpf.replace(/\./g, "");

  if (cpf.length !== 11) {
    return false;
  }

  var add = 0;
  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf.charAt(i), 10) * (10 - i);
  }
  var rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }
  if (rev !== parseInt(cpf.charAt(9), 10)) {
    return false;
  }

  add = 0;
  for (let i = 0; i < 10; i++) {
    add += parseInt(cpf.charAt(i), 10) * (11 - i);
  }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  return rev === parseInt(cpf.charAt(10), 10);
}

export function isDateValid(dateString) {
  // NOTE: IF DOING ANY DATE VALIDATION OTHER THAN DD/MM/YYYY, USE MODIFY TO USE moment.js.
  //       FOREGOING ADDING DEPENDENCY DUE TO BUNDLE SIZE CONSIDERATION.
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  const parts = dateString.split("/");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < new Date().getFullYear() - 100 || year > new Date().getFullYear() - 5 || month === 0 || month > 12) {
    return false;
  }

  var monthLength = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

export function isEmpty(obj) {
  if (obj === null || obj === undefined) {
    return true;
  }

  return Object.keys(obj).length === 0;
}

/**
 * Checks the string if undefined, null, not typeof string, empty or space(s).
 *
 * @param {any} str string to be evaluated
 * @returns {boolean} the evaluated result
 */
export function isStringNullOrWhiteSpace(str) {
  return str === undefined || str === null || typeof str !== "string" || str.match(/^\s*$/) !== null;
}
