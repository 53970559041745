import React from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedNumber, IntlProvider } from "react-intl";
import getSymbolFromCurrency from "currency-symbol-map";

const commonCurrencyFormat = (currency, symbol, value) => `${symbol}${value} ${currency}`;

const customFormats = {
  "en-US": {
    BOB: (currency, symbol, value) => `b$${value}`,
    COP: commonCurrencyFormat,
    MXN: commonCurrencyFormat,
    PEN: (currency, symbol, value) => `S/${value}`
  },
  "es-MX": {
    ARS: commonCurrencyFormat,
    CLP: commonCurrencyFormat,
    BOB: (currency, symbol, value) => `b$${value}`,
    PEN: (currency, symbol, value) => `S/${value}`,
    UYU: (currency, symbol, value) => `$${value} ${currency}`,
    COP: commonCurrencyFormat,
    MXN: commonCurrencyFormat,
    USD: commonCurrencyFormat
  },
  "ar-AE": {
    USD: (currency, symbol, value) => `${value}$`
  }
};

class Currency extends React.Component {
  getMinimumFractionDigits = (value) => {
    // 1.50 USD will be formatted as $1.50
    // 1.55 USD will be formatted as $1.55
    // 1.00 USD will be formatted as $1
    const fractions = (value * 100) % 100;
    return fractions > 0 ? 2 : 0;
  };

  getCurrencyFormat = () => {
    const { currency, value, intl } = this.props;
    const symbol = getSymbolFromCurrency(currency);
    const locale = intl.locale;

    /*
     * We do not want to `react-intl` converts digits to Arabic
     * With `ar_AE` key numbers converts to this: 12345 => ١٢٬٣٤٥
     * We are using `ar-TN` key to use European digits
     */
    const convertedLocale = locale === "ar-AE" ? "ar-TN" : locale;

    if (customFormats[locale] && customFormats[locale][currency]) {
      const formattedValue = new Intl.NumberFormat(convertedLocale, {
        minimumFractionDigits: this.getMinimumFractionDigits(value),
        maximumFractionDigits: 2
      }).format(value);
      return <span>{customFormats[locale][currency](currency, symbol, formattedValue)}</span>;
    }

    var formatProps = {
      value: value,
      style: "currency", // eslint-disable-line
      currency,
      currencyDisplay: "symbol",
      maximumFractionDigits: 2
    };

    if (this.props.setMinimumFractionDigits) {
      formatProps.minimumFractionDigits = this.getMinimumFractionDigits(value);
    }

    // Wrapping up FormattedNumber with IntlProvider to avoid converting digits to Arabic
    // See convertedLocale variable above
    return (
      <IntlProvider locale={convertedLocale} textComponent="span">
        <FormattedNumber {...formatProps} />
      </IntlProvider>
    );
  };

  render() {
    return this.getCurrencyFormat();
  }
}

Currency.propTypes = {
  value: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  setMinimumFractionDigits: PropTypes.bool.isRequired
};

Currency.defaultProps = {
  setMinimumFractionDigits: true
};

export default injectIntl(Currency);
