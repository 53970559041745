import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

const Breadcrumbs = ({ step }) => (
  <div className="breadcrumbs">
    <span className={classNames("step", { "text-highlight": step === 1 })}>
      <FormattedMessage id="step1_select" defaultMessage="Select" />
    </span>
    <span className="breadcrumbs-separator" />
    <span className={classNames("step", { "text-highlight": step === 2 })}>
      <FormattedMessage id="step2_details" defaultMessage="Details" />
    </span>
    <span className="breadcrumbs-separator" />
    <span className={classNames("step", { "text-highlight": step === 3 })}>
      <FormattedMessage id="step3_complete" defaultMessage="Complete" />
    </span>
  </div>
);

Breadcrumbs.propTypes = {
  step: PropTypes.number.isRequired
};

export default Breadcrumbs;
