import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PaymentOptionsFooter from "./PaymentOptionsFooter";
import PrepaidCodeWindow from "./PrepaidCodeWindow";
import PaymentOptionsSidebar from "../components/PaymentOptionsSidebar";
import PricePointList from "../components/PricePointList";
import DirectPurchaseConfirm from "../components/DirectPurchaseConfirm";
import Breadcrumbs from "../components/Breadcrumbs";
import CountriesModal from "../components/CountriesModal";
import KrPaymentOptions from "../components/kr/KrPaymentOptions";
import Tooltip from "../components/Tooltip";
import classNames from "classnames";
import analyticsClient from "../utils/analyticsPlatformClient";
import { getPricePointTelemetryDetails } from "../utils/pricePoint";

import {
  PREPAID_CARD,
  selectPaymentMethod,
  loadPaymentOptions,
  loadPaymentOptionsV2,
  loadPaymentOptionsPrepaidInline,
  loadPaymentOptionsPrepaidInlineV2,
  confirmDirectPurchase,
  confirmDirectPurchaseV2,
  confirmPricePointPurchase,
  confirmPricePointPurchaseV2,
  confirmPricePointPrepaidPurchase,
  confirmPricePointPrepaidPurchaseV2,
  getPaymentOptionsView,
  getOptionsLoadingState,
  getGame,
  getPlatform,
  getStorefront,
  getPurchaseAmount,
  getPurchaseCurrency,
  getPurchaseCurrencyName,
  getMinVirtualAmount,
  getCheckoutUrl,
  getPurchaseType,
  getPrepaidSuccess,
  getPrepaidFailureCode,
  getPrepaidInvalidReasonCode,
  getSelectedPaymentMethodId,
  getStorefrontCountries,
  clearPrepaidResults,
  getShowVat,
  getAllowCountryChange,
  getTaxDisclaimer,
  isDirectPurchase,
  getShowEuRightToCancel,
  getCommonPaymentMethodId,
  getCancelUrl,
  getSavedPaymentMethods,
  getDefaultPaymentMethod
} from "../redux/modules/paymentOptions";
import { getCurrentCountry } from "../redux/modules/user";
import {
  getSessionToken,
  getSessionIsKorea,
  getSessionUseApiV2,
  getSessionCurrentPurchaseId,
  getSessionStoreCode,
  getSessionPricePointId
} from "../redux/modules/session";

import { getPurchaseContext, loadPurchaseContextIfNeeded } from "../redux/modules/purchaseContext";
import EuRightToCancel from "./EuRightToCancel";
import { withNavigation } from "./NavigationProvider";

const FOOTER_EXEMPT_SFACS = ["lol-vn2"];

class PaymentOptions extends React.Component {
  state = {
    isRedirecting: false,
    countriesModalOpen: false,
    canStartPurchase: true,
    attemptedPurchase: false,
    selectedPurchasePricePointId: this.props.pricePointId || ""
  };

  componentDidMount() {
    if (this.props.isKorea) {
      if (this.props.useApiV2) {
        this.props.loadPaymentOptionsPrepaidInlineV2();
      } else {
        this.props.loadPaymentOptionsPrepaidInline(this.props.currentCountry);
      }
    } else {
      if (this.props.useApiV2) {
        this.props.loadPaymentOptionsV2();
      } else {
        this.props.loadPaymentOptions(this.props.currentCountry);
      }
    }
    this.props.loadPurchaseContextIfNeeded();
  }

  componentWillReceiveProps(nextProps) {
    // If country is not selected, need to show a modal dialog
    if (!nextProps.currentCountry.name && !nextProps.loading) {
      this.setState({
        countriesModalOpen: true
      });
    }

    if (nextProps.purchaseType) {
      sessionStorage.setItem("purchaseType", nextProps.purchaseType);
    }

    if (nextProps.checkoutUrl && !nextProps.isKorea) {
      this.setState({ isRedirecting: true });
      window.location = nextProps.checkoutUrl;
    }
  }

  onPricePointSelect = (id) => {
    let paymentMethodId = getCommonPaymentMethodId(this.props.selectedPaymentMethodId);
    const { paymentOptions, savedPaymentMethods, selectedPaymentMethodId, defaultPaymentMethod } = this.props;
    const paymentPosition = paymentOptions.findIndex((option) => {
      return option.paymentMethodId === paymentMethodId;
    });

    let telemetryDetails = getPricePointTelemetryDetails(
      this.props.paymentOptions,
      paymentMethodId,
      id,
      this.props.minVirtualAmount
    );

    analyticsClient.sendEvent("pricePointSelectedEvent", this.props.sessionToken, telemetryDetails);

    analyticsClient.sendEvent("paymentMethodSelectedEvent", this.props.sessionToken, {
      default: defaultPaymentMethod === selectedPaymentMethodId,
      saved: savedPaymentMethods.some((pm) => pm.paymentMethodId === paymentMethodId),
      paymentMethod: paymentMethodId,
      position: paymentPosition
    });

    if (this.props.useApiV2) {
      this.props.confirmPricePointPurchaseV2(paymentMethodId, id);
    } else {
      this.props.confirmPricePointPurchase(this.props.sessionToken, paymentMethodId, id);
    }
  };

  setSelectedPurchasePricePointId = (id) => {
    this.setState({ selectedPurchasePricePointId: id });
  };

  onChangeCountry = (country) => {
    if (this.props.useApiV2) {
      this.props.loadPaymentOptionsV2(country);
    } else {
      this.props.loadPaymentOptions(country);
    }
    this.setState({ countriesModalOpen: false });
  };

  onCountriesModalOpen = () => {
    this.setState({ countriesModalOpen: true });
  };

  onCountriesModalClose = () => {
    this.setState({ countriesModalOpen: false });
  };

  onDirectPurchaseConfirm = () => {
    let paymentMethodId = getCommonPaymentMethodId(this.props.selectedPaymentMethodId);
    if (this.props.useApiV2) {
      this.props.confirmDirectPurchaseV2(this.props.currentPurchaseId, paymentMethodId);
    } else {
      this.props.confirmDirectPurchase(this.props.sessionToken, paymentMethodId);
    }
  };

  onDirectPurchaseCancel = () => {
    let cancelUrl = this.props.cancelUrl;

    if (cancelUrl !== null || cancelUrl !== "") {
      window.location.href = cancelUrl;
    } else {
      this.props.navigate(-1);
    }
  };

  renderPrepaidCaptureView = () => {
    const confirmPricePointPrepaidPurchase = this.props.useApiV2
      ? this.props.confirmPricePointPrepaidPurchaseV2
      : this.props.confirmPricePointPrepaidPurchase;

    return (
      <PrepaidCodeWindow
        confirmPricePointPrepaidPurchase={confirmPricePointPrepaidPurchase}
        sessionToken={this.props.sessionToken}
        prepaidFailureCode={this.props.prepaidFailureCode}
        prepaidInvalidReasonCode={this.props.prepaidInvalidReasonCode}
        clearPrepaidResults={this.props.clearPrepaidResults}
        country={this.props.currentCountry}
        game={this.props.game}
        platform={this.props.platform}
      />
    );
  };

  renderPricePointView = () => {
    if (isDirectPurchase(this.props.purchaseType)) {
      return (
        <DirectPurchaseConfirm
          directPurchaseAmount={this.props.purchaseAmount}
          directPurchaseCurrency={this.props.purchaseCurrency}
          onConfirmClick={this.onDirectPurchaseConfirm}
          onCancelClick={this.onDirectPurchaseCancel}
        />
      );
    }

    return (
      <PricePointList
        pricePoints={this.getPricePoints(this.props.selectedPaymentMethodId)}
        onPricePointClick={
          this.props.storeCode === "2xko" ? this.setSelectedPurchasePricePointId : this.onPricePointSelect
        }
        selectedPurchasePricePointId={this.state.selectedPurchasePricePointId}
        canStartPurchase={this.state.canStartPurchase}
        attemptStartPurchase={this.attemptStartPurchase}
        hasDoubleBonusVc={this.getDoubleBonusVC(this.props.selectedPaymentMethodId) ? true : false}
        storeCode={this.props.storeCode}
        minVirtualAmount={this.props.minVirtualAmount}
      />
    );
  };

  getDoubleBonusVC = (paymentMethodId) => {
    return this.props.paymentOptions.find((option) => option.uniquePaymentMethodId === paymentMethodId)
      .hasDoubleBonusVc;
  };

  getPricePoints = (paymentMethodId) => {
    return this.props.paymentOptions
      .find((option) => option.uniquePaymentMethodId === paymentMethodId)
      .pricePoints.filter((pricePoint) => !!pricePoint.virtualCurrencyCode); // SKU price points not supported for selection
  };

  renderStep2TextBox = () => {
    if (this.props.selectedPaymentMethodId === PREPAID_CARD) {
      return <div />;
    }

    if (isDirectPurchase(this.props.purchaseType)) {
      return <FormattedMessage id="order_step2_confirm_direct_purchase" defaultMessage="Confirm Purchase Amount" />;
    }

    const vatDisclaimer = this.props.showVat ? (
      <span className="text-highlight">
        <FormattedMessage id="prices_include_VAT" />
      </span>
    ) : null;

    const reflectedCurrency = () => {
      return (
        this.props.currentCountry &&
        this.props.currentCountry.code3 === "ARG" &&
        ((this.props.selectedPaymentMethodId.includes("paypal") && <FormattedMessage id="reflected-currency-USD" />) ||
          (this.props.selectedPaymentMethodId.includes("dlocal-creditcard") && (
            <FormattedMessage id="reflected-currency-local" />
          )))
      );
    };

    return (
      <div id="select-vc">
        <span>
          <FormattedMessage
            id="order_step2"
            defaultMessage={`Select {vc_name}`}
            values={{
              vc_name: (
                <FormattedMessage
                  id={`vc_name_${this.props.purchaseCurrency}`.toLowerCase()}
                  defaultMessage={this.props.purchaseCurrencyName}
                />
              )
            }}
          />
        </span>
        &nbsp;&nbsp;
        <span id="reflected-currency">{reflectedCurrency()}</span>
        {/* TODO -- Double bonus RP workaround start -- Remove this when double RP event is done (2022-06-24) -- */}
        {this.getDoubleBonusVC(this.props.selectedPaymentMethodId) && (
          <mark style={{ backgroundColor: "#C8AA6E", borderRadius: "2px" }}>
            <FormattedMessage id="doubleRP_promo" />
          </mark>
        )}
        {vatDisclaimer}
      </div>
    );
  };

  renderNoPaymentOptionsAvailable = () => (
    <div className="container no-payment-options fade-in">
      <h3 className="no-payment-options-text">
        <FormattedMessage id="no_payment_methods" defaultMessage="No payment methods currently available." />{" "}
        {this.props.countries && this.props.countries.length > 0 && (
          <a href="#/" className="link text-highlight" onClick={this.onCountriesModalOpen}>
            <FormattedMessage id="select_different_country" defaultMessage="Please select a different country." />
          </a>
        )}
      </h3>
    </div>
  );

  renderFooter = () => {
    if (this.props.showEuRightToCancel || FOOTER_EXEMPT_SFACS.includes(this.props.storefrontAccountCode)) {
      return;
    }

    const hasPaymentOptions = this.props.paymentOptions.length > 0;

    if (hasPaymentOptions) {
      return (
        <PaymentOptionsFooter
          virtualCurrency={this.props.purchaseCurrency}
          showTaxDisclaimer={this.props.taxDisclaimer}
          isDirectPurchase={isDirectPurchase(this.props.purchaseType)}
        />
      );
    }
  };

  setCanStartPurchase = (canStartPurchase = false) => {
    this.setState({ canStartPurchase });
  };

  attemptStartPurchase = () => {
    this.setState({ attemptedPurchase: true });
  };

  onContinueClick = () => {
    // If a purchase can be started and a price point is selected,
    // continue into the purchase flow
    if (this.state.canStartPurchase && this.state.selectedPurchasePricePointId) {
      this.onPricePointSelect(this.state.selectedPurchasePricePointId);
    }
  };

  isContinueDisabled = () => {
    // Keep the Continue button disabled until a price point is selected
    // and a purchase can be started (must check EU legal checkbox if in EU)
    return !(this.state.canStartPurchase && this.state.selectedPurchasePricePointId);
  };

  render() {
    const {
      paymentOptions,
      currentCountry,
      loading,
      countries,
      prepaidSuccess,
      selectedPaymentMethodId,
      isKorea,
      sessionToken,
      checkoutUrl,
      purchaseContext,
      allowCountryChange,
      taxDisclaimer,
      minVirtualAmount,
      storeCode,
      pricePointId
    } = this.props;

    if (isKorea) {
      return (
        <KrPaymentOptions
          paymentOptions={paymentOptions}
          checkoutUrl={checkoutUrl}
          sessionToken={sessionToken}
          purchaseContext={purchaseContext}
        />
      );
    }

    const hasPaymentOptions = paymentOptions.length > 0;

    if (prepaidSuccess) {
      return <Navigate to="/checkout-success" />;
    }

    // Dynamically add classes to allow for prepaid card styling
    const showEuRightToCancel = this.props.showEuRightToCancel;
    const mainClassName = showEuRightToCancel ? `main terms-container` : "main";

    const showPrepaidCard = selectedPaymentMethodId === PREPAID_CARD;
    const mainContentClassName = showPrepaidCard ? "main-content prepaid" : "main-content";

    return (
      <div className="payment-flow">
        <Breadcrumbs step={1} />
        {loading || this.state.isRedirecting ? (
          <div className="container loading">
            <div className="loading-spinner" />
          </div>
        ) : (
          <div className="container">
            {!hasPaymentOptions ? (
              this.renderNoPaymentOptionsAvailable()
            ) : (
              <div className="payment-options fade-in">
                <PaymentOptionsSidebar
                  countries={countries}
                  paymentOptions={paymentOptions}
                  selectedPaymentMethodId={selectedPaymentMethodId}
                  currentCountry={currentCountry}
                  onChangeCountry={this.onChangeCountry}
                  onModalOpen={this.onCountriesModalOpen}
                  onPaymentMethodSelect={this.props.selectPaymentMethod}
                  platform={this.props.platform}
                  allowCountryChange={allowCountryChange}
                  taxDisclaimer={taxDisclaimer}
                />
                <div className={mainClassName}>
                  {!!pricePointId || (
                    <>
                      <h2>{this.renderStep2TextBox()}</h2>
                      {storeCode === "2xko" && minVirtualAmount > 0 && !showPrepaidCard && (
                        <h3 className="min-vc-amount-header">
                          <FormattedMessage
                            id="need_vc_to_purchase"
                            defaultMessage="To purchase this content, you'll need amount?."
                            values={{
                              min_virtual_amount: <span className="min-vc-amount">{minVirtualAmount}</span>
                            }}
                          />
                        </h3>
                      )}
                      <div className={mainContentClassName}>
                        {showPrepaidCard ? this.renderPrepaidCaptureView() : this.renderPricePointView()}
                      </div>
                    </>
                  )}
                  {showEuRightToCancel && (
                    <EuRightToCancel
                      setCanStartPurchase={this.setCanStartPurchase}
                      attemptedPurchase={this.state.attemptedPurchase}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {this.renderFooter()}
        {!showPrepaidCard && storeCode === "2xko" && (
          <div
            className={classNames("continue-container", {
              loading: loading
            })}
          >
            <Tooltip
              placement="bottom"
              trigger="hover"
              followCursor={true}
              tooltipBody={
                <div
                  className={classNames("continue-tooltip", {
                    show: this.isContinueDisabled()
                  })}
                >
                  {!this.state.selectedPurchasePricePointId ? (
                    <FormattedMessage id="tooltip_price_point" defaultMessage="Please select a price point." />
                  ) : (
                    <FormattedMessage id="tooltip_terms" defaultMessage="Please agree with the terms to proceed." />
                  )}
                </div>
              }
            >
              <button className="btn btn-continue" onClick={this.onContinueClick} disabled={this.isContinueDisabled()}>
                <FormattedMessage id="continue" defaultMessage="Continue" />
              </button>
            </Tooltip>
          </div>
        )}
        <CountriesModal
          contentLabel="CountriesModal"
          open={this.state.countriesModalOpen}
          currentCountry={currentCountry}
          countries={countries}
          onChangeCountry={this.onChangeCountry}
          onModalOpen={this.onCountriesModalOpen}
          onRequestClose={this.onCountriesModalClose}
        />
      </div>
    );
  }
}

PaymentOptions.propTypes = {
  paymentOptions: PropTypes.array.isRequired,
  loadPaymentOptions: PropTypes.func.isRequired,
  loadPaymentOptionsPrepaidInline: PropTypes.func,
  loadPurchaseContextIfNeeded: PropTypes.func.isRequired,
  confirmDirectPurchase: PropTypes.func.isRequired,
  confirmPricePointPurchase: PropTypes.func.isRequired,
  confirmPricePointPrepaidPurchase: PropTypes.func.isRequired,
  currentCountry: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object,
  sessionToken: PropTypes.string.isRequired,
  currentPurchaseId: PropTypes.string,
  pricePointId: PropTypes.string,
  game: PropTypes.string.isRequired,
  storefrontAccountCode: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  purchaseAmount: PropTypes.number,
  purchaseCurrency: PropTypes.string,
  purchaseCurrencyName: PropTypes.string,
  minVirtualAmount: PropTypes.number,
  checkoutUrl: PropTypes.string,
  cancelUrl: PropTypes.string,
  purchaseType: PropTypes.string,
  prepaidSuccess: PropTypes.bool,
  prepaidFailureCode: PropTypes.string,
  prepaidInvalidReasonCode: PropTypes.string,
  selectedPaymentMethodId: PropTypes.string,
  countries: PropTypes.array.isRequired,
  clearPrepaidResults: PropTypes.func.isRequired,
  showVat: PropTypes.bool,
  isKorea: PropTypes.bool.isRequired,
  purchaseContext: PropTypes.object,
  useApiV2: PropTypes.bool.isRequired,
  hasDoubleBonusVc: PropTypes.bool.isRequired,
  savedPaymentMethods: PropTypes.array.isRequired,
  defaultPaymentMethod: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  paymentOptions: getPaymentOptionsView(state),
  currentCountry: getCurrentCountry(state),
  loading: getOptionsLoadingState(state),
  sessionToken: getSessionToken(state),
  currentPurchaseId: getSessionCurrentPurchaseId(state),
  pricePointId: getSessionPricePointId(state),
  game: getGame(state),
  storefrontAccountCode: getStorefront(state),
  platform: getPlatform(state),
  storeCode: getSessionStoreCode(state),
  purchaseAmount: getPurchaseAmount(state),
  purchaseCurrency: getPurchaseCurrency(state),
  purchaseCurrencyName: getPurchaseCurrencyName(state),
  minVirtualAmount: getMinVirtualAmount(state),
  checkoutUrl: getCheckoutUrl(state),
  cancelUrl: getCancelUrl(state),
  purchaseType: getPurchaseType(state),
  prepaidSuccess: getPrepaidSuccess(state),
  prepaidFailureCode: getPrepaidFailureCode(state),
  prepaidInvalidReasonCode: getPrepaidInvalidReasonCode(state),
  selectedPaymentMethodId: getSelectedPaymentMethodId(state),
  countries: getStorefrontCountries(state),
  showVat: getShowVat(state),
  allowCountryChange: getAllowCountryChange(state),
  taxDisclaimer: getTaxDisclaimer(state),
  showEuRightToCancel: getShowEuRightToCancel(state),
  isKorea: getSessionIsKorea(state),
  purchaseContext: getPurchaseContext(state),
  useApiV2: getSessionUseApiV2(state),
  savedPaymentMethods: getSavedPaymentMethods(state),
  defaultPaymentMethod: getDefaultPaymentMethod(state)
});

export default connect(mapStateToProps, {
  loadPaymentOptions,
  loadPaymentOptionsV2,
  loadPaymentOptionsPrepaidInline,
  loadPaymentOptionsPrepaidInlineV2,
  loadPurchaseContextIfNeeded,
  selectPaymentMethod,
  confirmDirectPurchase,
  confirmDirectPurchaseV2,
  confirmPricePointPurchase,
  confirmPricePointPurchaseV2,
  confirmPricePointPrepaidPurchase,
  confirmPricePointPrepaidPurchaseV2,
  clearPrepaidResults
})(withNavigation(PaymentOptions));
