import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import "./PurchaseHeader.css";
import PropTypes from "prop-types";

const PurchaseHeader = ({ virtualCurrencyCode, storeCode }) => {
  const intl = useIntl();

  return (
    <div className="purchase-header">
      <a href={intl.formatMessage({ id: `game_link_${storeCode}` })}>
        <FormattedMessage
          id="kr_purchase_options_header_notice"
          defaultMessage="{game} {vc_name} 구매 창입니다."
          values={{
            vc_name: (
              <FormattedMessage
                id={`vc_code_${virtualCurrencyCode}`.toLowerCase()}
                defaultMessage={virtualCurrencyCode}
              />
            ),
            game: <FormattedMessage id={`game_name_${storeCode}`} defaultMessage={storeCode} />
          }}
        />
      </a>
    </div>
  );
};

PurchaseHeader.propTypes = {
  virtualCurrencyCode: PropTypes.string,
  storeCode: PropTypes.string
};

export default PurchaseHeader;
