import { createSelector } from "reselect";
import sortBy from "lodash/sortBy";
import { CALL_API } from "../middleware/api";
import { START_NEW_PURCHASE } from "./session";

export const SELECT_PAYMENT_METHOD = "pmc-client/payment-options/SELECT_PAYMENT_METHOD";
export const SELECT_PRICE_POINT = "pmc-client/payment-options/SELECT_PRICE_POINT";
export const LOAD = "pmc-client/payment-options/LOAD";
export const LOAD_SUCCESS = "pmc-client/payment-options/LOAD_SUCCESS";
export const LOAD_SUCCESS_V2 = "pmc-client/payment-options/LOAD_SUCCESS_V2";
export const LOAD_ALL_SUCCESS = "pmc-client/payment-options/LOAD_ALL_SUCCESS"; // Don't filter out prepaid methods, used in KR
export const LOAD_FAIL = "pmc-client/payment-options/LOAD_FAIL";

export const CONFIRM_DP = "pmc-client/payment-options/CONFIRM_DIRECT_PURCHASE";
export const CONFIRM_DP_SUCCESS = "pmc-client/payment-options/CONFIRM_DIRECT_PURCHASE_SUCCESS";
export const CONFIRM_DP_SUCCESS_V2 = "pmc-client/payment-options/CONFIRM_DIRECT_PURCHASE_SUCCESS_V2";
export const CONFIRM_DP_FAIL = "pmc-client/payment-options/CONFIRM_DIRECT_PURCHASE_FAIL";

export const CONFIRM_PP = "pmc-client/payment-options/CONFIRM_PRICE_POINT_PURCHASE";
export const CONFIRM_PP_SUCCESS = "pmc-client/payment-options/CONFIRM_PRICE_POINT_PURCHASE_SUCCESS";
export const CONFIRM_PP_FAIL = "pmc-client/payment-options/CONFIRM_PRICE_POINT_PURCHASE_FAIL";

export const CONFIRM_PREPAID_PP = "pmc-client/payment-options/CONFIRM_PRICE_POINT_PREPAID_PURCHASE";
export const CONFIRM_PREPAID_PP_SUCCESS = "pmc-client/payment-options/CONFIRM_PRICE_POINT_PREPAID_PURCHASE_SUCCESS";
export const CONFIRM_PREPAID_PP_INVALID = "pmc-client/payment-options/CONFIRM_PRICE_POINT_PREPAID_PURCHASE_INVALID";
export const CONFIRM_PREPAID_PP_FAIL = "pmc-client/payment-options/CONFIRM_PRICE_POINT_PREPAID_PURCHASE_FAIL";

export const CLEAR_PREPAID_PP_RESULTS = "pmc-client/payment-options/CLEAR_PRICE_POINT_PREPAID_PURCHASE_RESULTS";

export const INIT_VIRTUAL_CURRENCY = "pmc-client/payment-options/INIT_VIRTUAL_CURRENCY";

export const CLEAR_CHECKOUT_URL = "pmc-client/payment-options/CLEAR_CHECKOUT_URL";

export const PREPAID_CARD = "prepaidcard";

export const purchaseTypes = {
  DIRECT: "direct",
  PRICE_POINT: "price-point"
};

export const loadPaymentOptions = (country) => ({
  [CALL_API]: {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    endpoint: "/riotpay/pmc/v1/purchase-options",
    params: {
      country
    },
    headers: {
      "Cache-Control": "no-cache"
    }
  }
});

export const loadPaymentOptionsV2 = (country) => ({
  [CALL_API]: {
    types: [LOAD, LOAD_SUCCESS_V2, LOAD_FAIL],
    endpoint: "/riotpay/pmc/v2/purchase-options",
    params: {
      country,
      t: new Date().getTime() // IE 11 has a bug where it sometimes caches this important request
    },
    headers: {
      "Cache-Control": "no-cache"
    }
  }
});

export const loadPaymentOptionsPrepaidInline = (country) => ({
  [CALL_API]: {
    types: [LOAD, LOAD_ALL_SUCCESS, LOAD_FAIL],
    endpoint: "/riotpay/pmc/v1/purchase-options",
    params: {
      country
    },
    headers: {
      "Cache-Control": "no-cache"
    }
  }
});

export const loadPaymentOptionsPrepaidInlineV2 = () => ({
  [CALL_API]: {
    types: [LOAD, LOAD_ALL_SUCCESS, LOAD_FAIL],
    endpoint: "/riotpay/pmc/v2/purchase-options",
    params: {
      t: new Date().getTime() // IE 11 has a bug where it sometimes caches this important request
    },
    headers: {
      "Cache-Control": "no-cache"
    }
  }
});

export const confirmDirectPurchase = (sessionToken, paymentMethodId) => ({
  [CALL_API]: {
    types: [CONFIRM_DP, CONFIRM_DP_SUCCESS, CONFIRM_DP_FAIL],
    endpoint: `/riotpay/pmc/v1/direct-purchases/${sessionToken}/start`,
    data: {
      paymentMethodId
    }
  }
});

export const confirmDirectPurchaseV2 = (purchaseId, paymentMethodId) => ({
  [CALL_API]: {
    types: [CONFIRM_DP, CONFIRM_DP_SUCCESS_V2, CONFIRM_DP_FAIL],
    endpoint: `/riotpay/pmc/v2/direct-purchases/${purchaseId}/start`,
    data: {
      paymentMethodId
    }
  }
});

export const confirmPricePointPurchase = (sessionToken, paymentMethodId, pricePointId) => ({
  [CALL_API]: {
    types: [CONFIRM_PP, CONFIRM_PP_SUCCESS, CONFIRM_PP_FAIL],
    endpoint: `/riotpay/pmc/v1/price-point-purchases/${sessionToken}/start`,
    data: {
      paymentMethodId,
      pricePointId
    }
  }
});

export const confirmPricePointPurchaseV2 = (paymentMethodId, pricePointId) => ({
  [CALL_API]: {
    types: [CONFIRM_PP, CONFIRM_PP_SUCCESS, CONFIRM_PP_FAIL],
    endpoint: `/riotpay/pmc/v2/price-point-purchases/`,
    data: {
      paymentMethodId,
      pricePointId
    }
  }
});

export const confirmPricePointPrepaidPurchase = (sessionToken, prepaidCode, cpf, dateOfBirth, firstName, lastName) => ({
  [CALL_API]: {
    types: [CONFIRM_PREPAID_PP, CONFIRM_PREPAID_PP_SUCCESS, CONFIRM_PREPAID_PP_FAIL],
    endpoint: "/riotpay/pmc/v1/redeem-prepaid-code",
    data: {
      prepaidCode,
      cpf,
      firstName,
      lastName,
      dateOfBirth,
      purchaseToken: sessionToken
    }
  }
});

export const confirmPricePointPrepaidPurchaseV2 = (
  sessionToken,
  prepaidCode,
  cpf,
  dateOfBirth,
  firstName,
  lastName
) => ({
  [CALL_API]: {
    types: [CONFIRM_PREPAID_PP, CONFIRM_PREPAID_PP_SUCCESS, CONFIRM_PREPAID_PP_FAIL],
    endpoint: "/riotpay/pmc/v2/redeem-prepaid-code",
    data: {
      prepaidCode,
      cpf,
      firstName,
      lastName,
      dateOfBirth
    }
  }
});

export const prepaidPurchaseInvalid = (prepaidFailureCode, prepaidInvalidReasonCode) => ({
  type: CONFIRM_PREPAID_PP_INVALID,
  response: {
    prepaidFailureCode,
    prepaidInvalidReasonCode
  }
});

export const selectPaymentMethod = (paymentMethodId) => ({
  type: SELECT_PAYMENT_METHOD,
  selectedPaymentMethodId: paymentMethodId
});

export const selectPricePoint = (pricePointId) => ({
  type: SELECT_PRICE_POINT,
  selectedPricePointId: pricePointId
});

export const clearPrepaidResults = () => ({
  type: CLEAR_PREPAID_PP_RESULTS
});

export const clearCheckoutUrl = () => ({
  type: CLEAR_CHECKOUT_URL
});

export function initSessionVirtualCurreny(virtualCurrencyCode) {
  return {
    type: INIT_VIRTUAL_CURRENCY,
    virtualCurrencyCode: virtualCurrencyCode
  };
}

export const initialState = {
  loaded: false,
  loading: false,
  paymentOptions: [],
  storefrontCountries: [],
  storefrontAccountCode: "",
  regionCode: "",
  checkoutUrl: "",
  cancelUrl: "",
  amount: 0,
  currencyCode: "RP",
  minVirtualAmount: 0
};

export default function reducer(
  state = initialState,
  { type, selectedPaymentMethodId, selectedPricePointId, response, httpStatus, virtualCurrencyCode }
) {
  switch (type) {
    case INIT_VIRTUAL_CURRENCY:
      return {
        ...state,
        currencyCode: virtualCurrencyCode
      };
    case START_NEW_PURCHASE:
      return {
        ...state,
        checkoutUrl: "",
        prepaidSuccess: false
      };
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case SELECT_PAYMENT_METHOD: {
      return {
        ...state,
        selectedPaymentMethodId
      };
    }
    case SELECT_PRICE_POINT: {
      return {
        ...state,
        selectedPricePointId
      };
    }

    case LOAD_SUCCESS:
    case LOAD_SUCCESS_V2: {
      // update purchase options to remove any prepaid payment methods
      // if any prepaid payment methods were present then add the 'prepaidcard'
      // payment method which is a universal field used to input codes for
      // any prepaid psp
      const purchaseOptions = response && response.purchaseOptions ? response.purchaseOptions : [];
      let hasPrepaid = false;
      purchaseOptions.forEach((po) => {
        const paymentMethods = po.paymentMethods || [];
        hasPrepaid = hasPrepaid || paymentMethods.find((pm) => pm.prepaid);
        po.paymentMethods = paymentMethods.filter((pm) => !pm.prepaid); // eslint-disable-line no-param-reassign
      });

      const savedPaymentMethods = response && response.savedPaymentMethods ? response.savedPaymentMethods : [];

      const directPurchase = getDirectPurchase(response);
      const isDirectPurchase = directPurchase !== null;

      // TODO: remove direct purchase condition
      // for now don't add prepaid option if direct purchase
      // going forward other prepaid flows will be enabled
      if (hasPrepaid && !isDirectPurchase) {
        purchaseOptions[0].paymentMethods.push({
          paymentMethodId: PREPAID_CARD,
          position: Number.MAX_VALUE // make this the last option in the list
        });
      }

      // eslint-disable-next-line
      let virtualCurrencyCode = null;
      let virtualCurrencyName = null;
      if (
        purchaseOptions.length > 0 &&
        purchaseOptions[0].pricePoints &&
        purchaseOptions[0].pricePoints.length > 0 &&
        purchaseOptions[0].pricePoints[0].virtualCurrencyCode
      ) {
        virtualCurrencyCode = purchaseOptions[0].pricePoints[0].virtualCurrencyCode;
        virtualCurrencyName = purchaseOptions[0].pricePoints[0].virtualCurrencyName;
      }

      const selectedPaymentMethodId = getInitialSelectedPaymentMethodId(purchaseOptions, savedPaymentMethods);

      return {
        ...state,
        loading: false,
        loaded: true,
        paymentOptions: purchaseOptions,
        amount: isDirectPurchase
          ? directPurchase.amountInCents // Direct Purchase
          : response.minVirtualAmount, // Price Point purchase
        minVirtualAmount: response.minVirtualAmount, //minimum VC user needs before able to purchase in game content
        purchaseType: isDirectPurchase ? purchaseTypes.DIRECT : purchaseTypes.PRICE_POINT,
        currencyCode: isDirectPurchase
          ? directPurchase.currency // Direct Purchase
          : virtualCurrencyCode, // Price Point Purchase, should be response.minVirtualCurrencyCode for rfc737
        virtualCurrencyName: virtualCurrencyName,
        storefrontAccountCode: response && response.storefrontAccountCode ? response.storefrontAccountCode : "",
        storefrontCountries: response && response.storefrontCountries ? response.storefrontCountries : [],
        regionCode: response && response.storefrontAccountCode ? response.storefrontAccountCode.split("-")[1] : "",
        selectedPaymentMethodId: selectedPaymentMethodId,
        selectedPricePointId: getInitialSelectedPricePointId(purchaseOptions, selectedPaymentMethodId),
        defaultPaymentMethod: selectedPaymentMethodId, // the intially selected payment method
        savedPaymentMethods: savedPaymentMethods,
        showVat: response.showVat,
        allowCountryChange: response.allowCountryChange,
        taxDisclaimer: response.taxDisclaimer,
        showEuRightToCancel: response.showEuRightToCancel,
        cancelUrl: response.cancelUrl
      };
    }
    case CONFIRM_DP:
    case CONFIRM_PP:
    case CONFIRM_PREPAID_PP:
      return {
        ...state,
        loading: true,
        prepaidSuccess: undefined,
        prepaidFailureCode: undefined
      };
    case CONFIRM_PP_SUCCESS:
    case CONFIRM_DP_SUCCESS:
    case CONFIRM_DP_SUCCESS_V2: {
      return {
        ...state,
        loading: false,
        checkoutUrl: response && response.checkoutUrl
      };
    }
    case CONFIRM_PP_FAIL:
      return {
        ...state,
        loading: false
      };
    case CONFIRM_PREPAID_PP_FAIL: {
      if (httpStatus !== 429 && httpStatus !== 404) {
        return state;
      }

      // Mapping HTTP error codes to prepaid failure codes
      let prepaidFailureCode;

      if (httpStatus === 429) {
        prepaidFailureCode = "VELOCITY";
      } else if (httpStatus === 404) {
        prepaidFailureCode = "NOTFOUND";
      }

      return {
        ...state,
        loading: false,
        prepaidSuccess: false,
        prepaidFailureCode
      };
    }
    case CLEAR_PREPAID_PP_RESULTS: {
      return {
        ...state,
        prepaidFailureCode: "",
        prepaidInvalidReasonCode: ""
      };
    }
    case CONFIRM_PREPAID_PP_INVALID: {
      return {
        ...state,
        prepaidSuccess: false,
        prepaidFailureCode: response && response.prepaidFailureCode,
        prepaidInvalidReasonCode: response && response.prepaidInvalidReasonCode
      };
    }
    case CONFIRM_PREPAID_PP_SUCCESS:
      return {
        ...state,
        loading: false,
        prepaidSuccess: response && response.success,
        prepaidFailureCode: response && response.failureCode,
        prepaidInvalidReasonCode: response && response.invalidReasonCode
      };
    case CLEAR_CHECKOUT_URL:
      return {
        ...state,
        checkoutUrl: ""
      };
    case LOAD_ALL_SUCCESS: {
      // Don't remove prepaid payment methods for KR, where we have a hardcoded UI
      const purchaseOptions = response && response.purchaseOptions ? response.purchaseOptions : [];

      const savedPaymentMethods = response && response.savedPaymentMethods ? response.savedPaymentMethods : [];

      // eslint-disable-next-line
      let virtualCurrencyCode = "";
      let virtualCurrencyName = "";
      if (
        purchaseOptions.length > 0 &&
        purchaseOptions[0].pricePoints &&
        purchaseOptions[0].pricePoints.length > 0 &&
        purchaseOptions[0].pricePoints[0].virtualCurrencyCode
      ) {
        virtualCurrencyCode = purchaseOptions[0].pricePoints[0].virtualCurrencyCode;
        virtualCurrencyName = purchaseOptions[0].pricePoints[0].virtualCurrencyName;
      }

      const selectedPaymentMethodId = getInitialSelectedPaymentMethodId(purchaseOptions, savedPaymentMethods);

      return {
        ...state,
        loading: false,
        loaded: true,
        paymentOptions: purchaseOptions,
        amount: response.minVirtualAmount, // Price Point purchases only (potentially revisit this)
        minVirtualAmount: response.minVirtualAmount, //minimum VC user needs before able to purchase in game content
        purchaseType: purchaseTypes.PRICE_POINT, // Price points only
        currencyCode: virtualCurrencyCode, // Price Point Purchase, should be response.minVirtualCurrencyCode for rfc737
        virtualCurrencyName: virtualCurrencyName,
        storefrontAccountCode: response && response.storefrontAccountCode ? response.storefrontAccountCode : "",
        storefrontCountries: response && response.storefrontCountries ? response.storefrontCountries : [],
        regionCode: response && response.storefrontAccountCode ? response.storefrontAccountCode.split("-")[1] : "",
        selectedPaymentMethodId: selectedPaymentMethodId,
        selectedPricePointId: getInitialSelectedPricePointId(purchaseOptions, selectedPaymentMethodId),
        defaultPaymentMethod: selectedPaymentMethodId, // the intially selected payment method
        savedPaymentMethods: savedPaymentMethods,
        showVat: response.showVat,
        allowCountryChange: response.allowCountryChange,
        taxDisclaimer: response.taxDisclaimer
      };
    }
    default:
      return state;
  }
}

// foreach group
// add payment method with price itmes sorted by order

// data structure final

// paymentMethod: {
//  paymentMethodId: 'paymentMethodName',
//  pricePoints: [
//    price: 5.99,
//    currency: USD,
//    virtualCurrencyName: 'RP'
// ]
// }

function getDirectPurchase(response) {
  if (!response) {
    return null;
  }

  if (response.directPurchase) {
    // Direct purchase v2
    return {
      amountInCents: response.directPurchase.amountInCents,
      currency: response.directPurchase.currency
    };
  }

  if (response.amount) {
    // Direct purchase v1
    return {
      amountInCents: response.amount,
      currency: response.currencyCode
    };
  }

  return null;
}

function getInitialSelectedPaymentMethodId(paymentOptions, savedPaymentMethods) {
  const options = flattenAndSortPaymentOptions(paymentOptions);

  // Return the payment option that matches the default saved payment method
  if (savedPaymentMethods && savedPaymentMethods.length > 0) {
    const defaultPaymentMethod = savedPaymentMethods.find((method) => method.default);
    if (defaultPaymentMethod) {
      const matchingOption = options.find((option) =>
        // default payment methods may use the psp name as the payment method id (e.g., "adyencomponents" instead of "adyencomponents-card")
        option.paymentMethodId.includes(defaultPaymentMethod.paymentMethodId)
      );

      if (matchingOption) {
        return createUniquePaymentMethodId(matchingOption.paymentMethodId, matchingOption.paymentMethodGroupId);
      }
    }
  }

  // Return the payment option with the lowest position
  if (options.length > 0) {
    const firstPaymentMethod = options[0];
    return createUniquePaymentMethodId(firstPaymentMethod.paymentMethodId, firstPaymentMethod.paymentMethodGroupId);
  }

  // When no payment options are available
  return null;
}

function flattenAndSortPaymentOptions(paymentOptions) {
  const flattenedPaymentOptions = paymentOptions.reduce((result, option) => {
    option.paymentMethods.forEach((method) => {
      result.push({
        paymentMethodId: method.paymentMethodId,
        paymentMethodGroupId: option.paymentMethodGroupId,
        position: method.position
      });
    });
    return result;
  }, []);

  flattenedPaymentOptions.sort((a, b) => a.position - b.position);

  return flattenedPaymentOptions;
}

function getInitialSelectedPricePointId(paymentOptions, selectedPaymentMethodId) {
  // When no payment options are available
  if (!paymentOptions || !Array.isArray(paymentOptions) || !selectedPaymentMethodId) {
    return null;
  }

  selectedPaymentMethodId = getCommonPaymentMethodId(selectedPaymentMethodId);

  // Find all price points for the selected payment method
  const pricePoints = paymentOptions
    .filter((option) => option.paymentMethods?.some((pm) => pm.paymentMethodId === selectedPaymentMethodId))
    .flatMap((option) => option.pricePoints || [])
    .sort((a, b) => a.realAmountCents - b.realAmountCents);

  return pricePoints.length > 0 ? pricePoints[0].id : null;
}

/* SELECTORS */
const getPaymentOptions = (state) => state.paymentOptions.paymentOptions;
export const getStorefront = (state) => state.paymentOptions.storefrontAccountCode;
export const getOptionsLoadingState = (state) => state.paymentOptions.loading;
export const getOptionsLoadedState = (state) => state.paymentOptions.loaded;
export const getPurchaseAmount = (state) => state.paymentOptions.amount;
// purchase currency = virtual currency
export const getPurchaseCurrency = (state) => state.paymentOptions.currencyCode;
export const getPurchaseCurrencyName = (state) => state.paymentOptions.virtualCurrencyName;
export const getMinVirtualAmount = (state) => state.paymentOptions.minVirtualAmount;
export const getCountries = (state) => state.paymentOptions.storefrontCountries;
export const getRegion = (state) => state.paymentOptions.regionCode;
export const getStorefrontCountries = createSelector([getCountries], (countries) =>
  countries.map((country) => ({
    value: country.code3,
    label: country.name
  }))
);
export const getShowVat = (state) => state.paymentOptions.showVat;
export const getAllowCountryChange = (state) => state.paymentOptions.allowCountryChange;
export const getTaxDisclaimer = (state) => state.paymentOptions.taxDisclaimer;
export const getShowEuRightToCancel = (state) => state.paymentOptions.showEuRightToCancel;
export const getPurchaseType = (state) => state.paymentOptions.purchaseType;
export const isDirectPurchase = (purchaseType) => purchaseType === purchaseTypes.DIRECT;

export const getPlatform = createSelector([getStorefront], (storefront) => {
  const split = storefront.split("-");
  return split.length >= 2 ? split[1] : "";
});

export const getGame = createSelector([getStorefront], (storefront) => {
  const split = storefront.split("-");
  return split.length >= 2 ? split[0] : "";
});

export const createUniquePaymentMethodId = (paymentMethodId, paymentMethodGroupId) =>
  paymentMethodId === PREPAID_CARD ? PREPAID_CARD : paymentMethodId + "::" + paymentMethodGroupId;
export const getCommonPaymentMethodId = (uniquePaymentMethodId) => uniquePaymentMethodId.split("::")[0];
export const getCheckoutUrl = (state) => state.paymentOptions.checkoutUrl;
export const getCancelUrl = (state) => state.paymentOptions.cancelUrl;
export const getPrepaidSuccess = (state) => state.paymentOptions.prepaidSuccess;
export const getPrepaidFailureCode = (state) => state.paymentOptions.prepaidFailureCode;
export const getPrepaidInvalidReasonCode = (state) => state.paymentOptions.prepaidInvalidReasonCode;
export const getSelectedPaymentMethodId = (state) => state.paymentOptions.selectedPaymentMethodId;
export const getSelectedPricePointId = (state) => state.paymentOptions.selectedPricePointId;
export const getPurchasedPricePoint = (state) => state.checkout.purchasedPricePoint;
export const getPurchasedPlayerFacingId = (state) => state.checkout.playerFacingId;
export const getSavedPaymentMethods = (state) => state.paymentOptions.savedPaymentMethods;
export const getDefaultPaymentMethod = (state) => state.paymentOptions.defaultPaymentMethod;

export const getPaymentOptionsView = createSelector([getPaymentOptions], (options) => {
  // reduce all groups into an array of the final player view
  const groupedPaymentMethods = options.reduce((result, optionGroup) => {
    // Reduce payment method array to include object with price point array
    const groupPaymentMethods = optionGroup.paymentMethods.reduce((methodsResult, method) => {
      methodsResult.push({
        paymentMethodId: method.paymentMethodId,
        paymentInstrumentNameLocalized: method.paymentInstrumentNameLocalized,
        uniquePaymentMethodId: createUniquePaymentMethodId(method.paymentMethodId, optionGroup.paymentMethodGroupId),
        hasDoubleBonusVc: optionGroup.hasDoubleBonusVc,
        pricePoints: optionGroup.pricePoints.sort((a, b) => a.realAmountCents - b.realAmountCents),
        position: method.position
      });
      return methodsResult;
    }, []);

    result.push(...groupPaymentMethods);
    return result;
  }, []);

  // Sort payment method array based on position
  return sortBy(groupedPaymentMethods, "position");
});
