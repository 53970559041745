import { createContext, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const NavigationContext = createContext();

export function NavigationProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const value = { navigate, location };

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
}

export function withNavigation(Component) {
  return function WrappedComponent(props) {
    const { navigate, location } = useContext(NavigationContext);
    return <Component {...props} navigate={navigate} location={location} />;
  };
}

export default NavigationContext;
