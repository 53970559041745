export function findPricePointForId(paymentOptions, pricePointId) {
  return paymentOptions
    .flatMap((paymentOption) => paymentOption.pricePoints)
    .find((pricePoint) => {
      return pricePointId === pricePoint.id;
    });
}

export function getPricePointAmount(pricePoint) {
  if (!pricePoint) {
    return 0;
  }

  const virtualAmount = pricePoint.virtualAmount || 0;
  const virtualBonusAmount = pricePoint.virtualBonusAmount || 0;
  return virtualAmount + virtualBonusAmount;
}

export function getVcPricePointsForPaymentMethod(paymentOptions, paymentMethodId) {
  return paymentOptions
    .filter((option) => option.uniquePaymentMethodId === paymentMethodId)
    .flatMap((option) => option.pricePoints)
    .filter((pricePoint) => !!pricePoint.virtualCurrencyCode); // SKU price points not supported for selection
}

function getRecommendedPricePoint(paymentOption, minVirtualAmount) {
  return paymentOption.pricePoints.find((pricePoint) => getPricePointAmount(pricePoint) >= minVirtualAmount);
}

function findPaymentOptionById(paymentOptions, paymentMethodId) {
  return paymentOptions.find((paymentOption) => paymentOption.paymentMethodId === paymentMethodId);
}

function findPricePointIndex(pricePoints, pricePointId) {
  return pricePoints.findIndex((pricePoint) => pricePoint.id === pricePointId);
}

export function getPricePointTelemetryDetails(paymentOptions, paymentMethodId, pricePointId, minVirtualAmount) {
  const paymentOption = findPaymentOptionById(paymentOptions, paymentMethodId);
  const pricePointPosition = findPricePointIndex(paymentOption.pricePoints, pricePointId);
  const pricePoint = paymentOption.pricePoints[pricePointPosition];
  const recommendedPricePoint = getRecommendedPricePoint(paymentOption, minVirtualAmount);

  if (!paymentOption || pricePointPosition === -1 || !pricePoint) {
    return null;
  }

  return {
    position: pricePointPosition,
    recommended: recommendedPricePoint?.id === pricePointId,
    realAmountCents: pricePoint.realAmountCents,
    virtualCurrency: {
      virtualAmount: pricePoint.virtualAmount,
      virtualBonusAmount: pricePoint.virtualBonusAmount,
      virtualCurrencyCode: pricePoint.virtualCurrencyCode
    }
  };
}
