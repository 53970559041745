import React from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";

import titleImg from "../../static/img/kr/riotgames_title.jpg";
import batteryImg from "../../static/img/kr/battery_logo.png";
import giftLogo from "../../static/img/kr/gift_logo.png";
import riotGamesLogo from "../../static/img/kr/riotgames_logo.png";

import "./ConsentLeftPanel.css";

class ConsentLeftPanel extends React.Component {
  render() {
    // TODO: Waiting Tony to verify if need to display summoner name
    return (
      <div className="kr-consent-left-panel">
        <div className="content">
          <img src={titleImg} alt="RIOT GAMES KOREA" />
          <img className="battery" src={this.props.isGift ? giftLogo : batteryImg} alt="RP" />
          {this.props.summonerName && (
            <div className="kr-consent-summoner-name">
              {this.props.summonerName}
              <FormattedMessage id="kr_summoner_welcome" />
            </div>
          )}
          <div className="kr-consent-agreement-title">
            <span>
              <FormattedMessage id="kr_minor_billing_agreement" />
            </span>
          </div>
          <img className="rg-logo" src={riotGamesLogo} alt="Riot Games" width="100px" />
        </div>
      </div>
    );
  }
}

ConsentLeftPanel.propTypes = {
  intl: PropTypes.object.isRequired,
  isGift: PropTypes.bool.isRequired,
  summonerName: PropTypes.string.isRequired
};

export default injectIntl(ConsentLeftPanel);
