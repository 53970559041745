import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

const PaymentError = ({ error, errorCode, onAckError }) => {
  let errorTextStyle;
  // work around for the large error text that doesn't fit into the error image
  if (errorCode === "cc-declined-check-pending" || errorCode === "cc-velocity-card") {
    errorTextStyle = {
      fontSize: "0.9em"
    };
  }

  return (
    <div className="error-page">
      <div className="error-box">
        <h1>
          <FormattedMessage id={error ? error : "error"} defaultMessage="Error" />
        </h1>
        <div className="error-text" style={errorTextStyle}>
          <FormattedMessage
            id={errorCode ? errorCode : "payment_error_text"}
            defaultMessage="You have encountered an error. Please try again later."
            values={{
              link: <FormattedMessage id="support_error_link" defaultMessage="" />
            }}
          />
        </div>
        <button className="btn btn-default" onClick={onAckError}>
          <FormattedMessage id="button_back" defaultMessage="Back" />
        </button>
      </div>
    </div>
  );
};

PaymentError.propTypes = {
  onAckError: PropTypes.func.isRequired,
  error: PropTypes.string,
  errorCode: PropTypes.string
};

export default PaymentError;
