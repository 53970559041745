import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

class LicenseDisclaimer extends React.Component {
  licenseDisclaimer = (
    <a
      href={this.props.intl.formatMessage({
        id: "license_disclaimer_link",
        defaultMessage: "https://www.riotgames.com/en/terms-of-service#id.2xcytpi"
      })}
      target="_blank"
      rel="noopener noreferrer"
      className="link text-highlight"
    >
      <FormattedMessage id="learn_more" defaultMessage="Learn more" />
    </a>
  );
  render() {
    return (
      <span className="license_disclaimer text-primary">
        <FormattedMessage
          id="license_disclaimer"
          defaultMessage="This purchase grants a license to this digital good."
        />
        &nbsp;
        {this.licenseDisclaimer}
      </span>
    );
  }
}

export default injectIntl(LicenseDisclaimer);
