import React, { useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { RootContext } from "./Root";
import { getAuthToken } from "../redux/modules/user";
import { getError, getErrorReason, errorReasonEnum } from "../redux/modules/error";
import { getSessionIsKorea } from "../redux/modules/session";
import createLogger from "../utils/logger";
import ErrorHandler from "./ErrorHandler";
import KrErrorHandler from "../components/kr/KrErrorHandler";
import { withNavigation } from "./NavigationProvider";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logger: null
    };
  }

  componentDidMount() {
    Modal.setAppElement(".app-main");
    const { apiClient } = this.context;
    if (apiClient) {
      this.setState({ logger: createLogger(apiClient, this.props.token) });
    }
  }

  getAppContainer(children) {
    return <div className="app-main">{children}</div>;
  }

  render() {
    const { isKorea, error, errorReason, children } = this.props;

    // This is for handling payment errors - for client errors, see ErrorBoundary
    if (error) {
      if (isKorea && errorReason === errorReasonEnum.VELOCITY_ERROR) {
        return this.getAppContainer(children);
      }
      if (isKorea) {
        return <KrErrorHandler errorReason={errorReason} />;
      }
      return <ErrorHandler errorReason={errorReason} />;
    }

    return this.getAppContainer(children);
  }
}

App.propTypes = {
  children: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  isKorea: PropTypes.bool.isRequired,
  error: PropTypes.string,
  errorReason: PropTypes.number,
  navigate: PropTypes.func
};

export default connect((state) => ({
  token: getAuthToken(state),
  isKorea: getSessionIsKorea(state),
  error: getError(state),
  errorReason: getErrorReason(state)
}))(withNavigation(App));
