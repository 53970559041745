import "react-app-polyfill/ie9"; // IE 9+ compatibility
import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";
import createStore from "./redux/createStore";
import WebFont from "webfontloader";
import ApiClient from "./utils/ApiClient";
import { applyGlobalCssTheme } from "./utils/themes";
import { addOrIncrementRetryCountInUrl } from "./utils/formatters";
import * as Sentry from "@sentry/browser";
import { initialState as paymentOptionsInitialState } from "./redux/modules/paymentOptions";
import ContainerRoot from "./containers/Root";
import { getBcp47Locale, loadLocaleData, loadTranslations, DEFAULT_LOCALE } from "./i18n";
import "./polyfill/ArrayFind";

import { datadogRum } from "@datadog/browser-rum-slim";

const pmcClientVersion = process.env.REACT_APP_PMC_VERSION;
const apiClient = new ApiClient(process.env.REACT_APP_PMC_EDGE);
const preloadedState = preloadStateFromSession(paymentOptionsInitialState);
const store = createStore(preloadedState, apiClient);
const locale = resolveUserLocale();

const isKorea = resolveIsKorea();

if (process.env.REACT_APP_DATADOG_RUM_ENABLED === "true") {
  datadogRum.init({
    applicationId: "eeb782da-06f2-4071-97f7-59fd19b81740",
    clientToken: "pub62fae2885bf206810794765cd94d295b",
    site: "datadoghq.com",
    service: "pmc-client",
    env: process.env.REACT_APP_SELF_AUTH_ENV,
    version: pmcClientVersion,
    sessionSampleRate: 0.5
  });
}

// Don't spam Sentry.io with development error logs
if (pmcClientVersion !== "dev") {
  Sentry.init({
    dsn: "https://11b27fbc6329475a895a0191c8d60ee4@sentry.io/1384474",
    environment: getPmcClientEnvironment(),
    release: "pmc-client@" + pmcClientVersion,
    attachStacktrace: true,
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
      // Payments team custom "these are not actual issues" ignore list below:
      "$ is not defined",
      "'$' is undefined",
      "Unexpected token o in JSON at position 1"
    ],
    blacklistUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      // Payments team custom blacklist sites below:
      /m\.feiqinwangshi\.cn\//i
    ]
  });

  Sentry.withScope((scope) => {
    scope.setTag("version", pmcClientVersion);
  });
}

/* only load korean fonts if catalog is for a korean store */
if (isKorea) {
  WebFont.load({
    google: {
      families: ["Nanum Gothic:400,700:korean"]
    }
  });
}

// korea players may be using IE 10
// react-intl uses the built-in Intl APIs which are not present in IE until v11 so must use this polyfill
if (!global.Intl) {
  import("intl")
    .then(() => loadTranslationsAndRenderRoot())
    .catch((error) => Sentry.captureException(error));
} else {
  // If Intl is available (most non-IE10 cases) go ahead and load normally
  loadTranslationsAndRenderRoot();
}

/* Dynamically loading translations and then rendering the app */
function loadTranslationsAndRenderRoot() {
  loadTranslations(locale)
    .then((translationsForUsersLocale) => {
      // Region defined to <html> element for RTL implementation
      const filteredRegion = locale.substring(0, 2);
      if (filteredRegion) {
        document.documentElement.setAttribute("lang", filteredRegion);
      }

      // This is needed when the buyer reloads the page, we already know
      // the theme in this case since it is saved in the session storage
      applyGlobalCssTheme(preloadedState.session.cssTheme);

      const root = createRoot(document.getElementById("root"));
      root.render(
        <ContainerRoot store={store} locale={locale} messages={translationsForUsersLocale} apiClient={apiClient} />
      );
    })
    .catch((err) => {
      // At this point the translation chunk failed to load (most likely due to a network problem)
      Sentry.captureException(err);
      // Giving browser a second to send the error and then proceed to reload the window.
      setTimeout(function () {
        let incrementResult = addOrIncrementRetryCountInUrl(window.location.href);
        if (incrementResult.retry <= 2) {
          window.location.href = incrementResult.url;
        } else {
          Sentry.captureMessage("Failed to load translation chunk 3 times");
        }
      }, 1000);
    });
}

function preloadStateFromSession(paymentOptionsInitialState) {
  const initialState = {
    session: {
      prepaidMode: false,
      token: "",
      instance: "",
      isKorea: false,
      useApiV2: false,
      currentPurchaseId: null,
      cssTheme: null,
      storeCode: null,
      isNewFlow: false
    },
    paymentOptions: {
      ...paymentOptionsInitialState
    }
  };

  const sessionToken = sessionStorage.getItem("sessionToken");
  if (sessionToken) {
    initialState.session.token = sessionToken;
  }

  const instance = sessionStorage.getItem("instance");
  if (instance) {
    initialState.session.instance = instance;
  }

  const prepaidMode = sessionStorage.getItem("prepaidMode");
  if (prepaidMode) {
    initialState.session.prepaidMode = prepaidMode === "true";
  }

  const isKorea = sessionStorage.getItem("isKorea");
  if (isKorea) {
    initialState.session.isKorea = isKorea === "true";
  }

  const purchaseType = sessionStorage.getItem("purchaseType");
  if (purchaseType) {
    initialState.paymentOptions.purchaseType = purchaseType;
  }

  const useApiV2 = sessionStorage.getItem("useApiV2");
  if (useApiV2) {
    initialState.session.useApiV2 = useApiV2 === "true";
  }

  const currentPurchaseId = sessionStorage.getItem("currentPurchaseId");
  // Need to check for "null" since otherwise we will have a string "null" stored in the session storage
  if (currentPurchaseId && currentPurchaseId !== "null") {
    initialState.session.currentPurchaseId = currentPurchaseId;
  }

  const pricePointId = sessionStorage.getItem("pricePointId");
  if (pricePointId) {
    initialState.session.pricePointId = pricePointId;
  }

  const cssTheme = sessionStorage.getItem("cssTheme");
  if (cssTheme) {
    initialState.session.cssTheme = cssTheme;
  }

  const storeCode = sessionStorage.getItem("storeCode");
  if (storeCode) {
    initialState.session.storeCode = storeCode;
  }

  const virtualCurrencyCode = sessionStorage.getItem("virtualCurrencyCode");
  if (virtualCurrencyCode) {
    initialState.paymentOptions.currencyCode = virtualCurrencyCode;
  }

  const isNewFlow = sessionStorage.getItem("isNewFlow") === "true";
  if (isNewFlow) {
    initialState.session.isNewFlow = isNewFlow;
  }

  return initialState;
}

export function resolveUserLocale() {
  const urlLocale = getBcp47Locale();
  const sessionLocale = sessionStorage.getItem("locale");

  return urlLocale || sessionLocale || DEFAULT_LOCALE;
}

// To set "environment" in Sentry error reporting
function getPmcClientEnvironment() {
  const localMatch = window.location.href.indexOf("localhost") > -1;
  const stageMatch = window.location.href.indexOf("client.stage.pmc.pay.riotgames.com") > -1;
  const preprodMatch = window.location.href.indexOf("client.preprod.pmc.pay.riotgames.com") > -1;
  if (stageMatch) {
    return "stage";
  } else if (localMatch) {
    return "local";
  } else if (preprodMatch) {
    return "preprod";
  } else {
    return "prod";
  }
}

function resolveIsKorea() {
  const urlIsKorea = getIsKoreaFromUrl();
  const sessionIsKorea = sessionStorage.getItem("isKorea");

  return urlIsKorea || sessionIsKorea === "true";
}

function getIsKoreaFromUrl() {
  // can only be a 0 or 1 and if missing it'll be 0
  const queryKoreaMatch = new RegExp(/k=([0,1])/);
  const match = window.location.href.match(queryKoreaMatch);
  if (match && match.length && match.length === 2) {
    // k=1 query param means that this IS for korea
    return match[1] === "1";
  }
  // if 'k' not present in URL we default to NOT korea
  return false;
}
