import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { resetErrorMessage } from "../redux/modules/error";
import { applyGlobalCssTheme } from "../utils/themes";
import { FormattedMessage } from "react-intl";

/* eslint-disable react/prefer-stateless-function */
class InvalidGiftee extends React.Component {
  componentWillMount() {
    // Hardcoding League theme here due to RP gifting issue during Hall Of Legends
    applyGlobalCssTheme("lol");
  }

  render() {
    return (
      <div className="error-page">
        <div className="error-box">
          <div className="error-text">
            <FormattedMessage
              id={"invalid_giftee"}
              defaultMessage="Payment has been canceled due to gifting restriction"
            />
          </div>

          <div>
            <button className="btn btn-default" onClick={window.close}>
              <FormattedMessage id="button_close" defaultMessage="Close" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

InvalidGiftee.propTypes = {
  resetErrorMessage: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

export default connect(null, { resetErrorMessage })(InvalidGiftee);
