import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

import "./ConsentFormRegister.css";

class ConsentFormRegister extends React.Component {
  onCancelClicked = (e) => {
    // Close LCU window.
    let parentWindow = window.opener;

    if (parentWindow && parentWindow.opener) {
      parentWindow = parentWindow.opener;
    }

    // LCU is responsible closing the window.
    if (parentWindow) {
      try {
        parentWindow.postMessage({ action: "closePaymentsWindow" }, "*");
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log("Current window does not have a parent window.");
    }

    // Close player browser.
    window.close();
  };

  onSubmitClicked = (e) => {
    this.props.onRegisterClick();
  };

  render() {
    return (
      <div className="kr-consent-form-register" style={{ marginTop: "20px", textAlign: "right", paddingBottom: "5px" }}>
        <div className="kr-consent-submit-button" onClick={this.onSubmitClicked}>
          <FormattedMessage id="kr_submit" />
        </div>
        <div className="kr-consent-cancel-button" onClick={this.onCancelClicked}>
          <FormattedMessage id="kr_cancel" />
        </div>
      </div>
    );
  }
}

ConsentFormRegister.propTypes = {
  intl: PropTypes.object.isRequired,
  onRegisterClick: PropTypes.func.isRequired
};

export default injectIntl(ConsentFormRegister);
