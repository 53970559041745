import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getError, getErrorReason, resetErrorMessage } from "../redux/modules/error";
import queryString from "query-string";
import PaymentError from "../components/PaymentError";
import PaymentExpired from "../components/PaymentExpired";
import { getSessionIsNewFlow } from "../redux/modules/session";
import analyticsClient from "../utils/analyticsPlatformClient";
import { withNavigation } from "./NavigationProvider";

/* eslint-disable react/prefer-stateless-function */
class PaymentFailed extends React.Component {
  state = {};

  componentWillMount() {
    const query = queryString.parse(this.props.location.search);
    if (query.customerror) {
      this.setState({
        errorCode: query.customerror
      });
    }
  }

  onAckError = () => {
    this.props.resetErrorMessage();

    if (this.props.isNewFlow) {
      this.props.navigate("/payment-method-selection");
    } else {
      this.props.navigate("/");
    }
  };

  render() {
    analyticsClient.sendEvent("sessionErrorEvent", this.props.sessionToken, {
      errorMessage: this.props.error,
      errorCode: this.props.errorCode
    });
    if (this.state.errorCode === "payment_expired_text") {
      return <PaymentExpired />;
    } else {
      return <PaymentError errorCode={this.state.errorCode} onAckError={this.onAckError} />;
    }
  }
}

PaymentFailed.propTypes = {
  resetErrorMessage: PropTypes.func.isRequired,
  errorCode: PropTypes.number,
  error: PropTypes.string,
  location: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isNewFlow: getSessionIsNewFlow(state),
  error: getError(state),
  errorCode: getErrorReason(state)
});

export default connect(mapStateToProps, { resetErrorMessage })(withNavigation(PaymentFailed));
