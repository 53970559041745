import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import Currency from "./Currency";
import VirtualCurrencyAmount from "./VirtualCurrencyAmount";

const PricePointCheckoutDetail = ({
  purchasedPricePoint: {
    realAmountCents,
    realCurrencyCode,
    virtualAmount,
    virtualBonusAmount,
    virtualCurrencyName,
    virtualCurrencyCode
  },
  salesTax,
  newBalance
}) => {
  // If purchase has sales tax, use total amount after tax.
  const grandTotal = salesTax ? salesTax.totalAmountCents : realAmountCents;

  const formattedGrandTotalAmount = <Currency value={grandTotal / 100} currency={realCurrencyCode} />;

  const renderTax = () => {
    if (!salesTax) {
      return null;
    }

    const formattedNetAmount = (
      <Currency value={salesTax.netAmountCents / 100} currency={realCurrencyCode} setMinimumFractionDigits={false} />
    );

    const formattedSalesTax = <Currency value={salesTax.saleTaxInCents / 100} currency={realCurrencyCode} />;

    return (
      <div>
        <div className="totalRow">
          <div className="label">
            <FormattedMessage id="net" defaultMessage="Net" />:
          </div>
          <div className="value">{formattedNetAmount}</div>
        </div>
        <div className="totalRow">
          <div className="label">
            <FormattedMessage id="salesTax" defaultMessage="Sales Tax" />:
          </div>
          <div className="value">{formattedSalesTax}</div>
        </div>
      </div>
    );
  };

  const renderBalance = () => {
    if (!newBalance) {
      return null;
    }
    return (
      <div className="newBalanceRowComplete">
        <div className="label">
          <FormattedMessage id={`new_balance`} defaultMessage={`New Balance`} />:
        </div>
        <div className="value">
          <VirtualCurrencyAmount value={newBalance} />
        </div>
      </div>
    );
  };
  const renderDivider = () => {
    return <div className="dividerRow" />;
  };
  const renderPrice = (formattedGrandTotalAmount) => {
    if (salesTax) {
      return <div className="price" />;
    }

    return <div className="price">{formattedGrandTotalAmount}</div>;
  };

  const totalVc = virtualAmount + virtualBonusAmount;

  return (
    <div className="order-detail">
      <h2>
        <FormattedMessage id="order_details" defaultMessage="Order Details" />
      </h2>
      <div className="rpCard">
        {renderPrice(formattedGrandTotalAmount)}
        {virtualAmount && (
          <>
            <div className="vc">
              <VirtualCurrencyAmount value={virtualAmount} />{" "}
              <FormattedMessage
                id={`vc_name_${virtualCurrencyCode.toLowerCase()}`}
                defaultMessage={virtualCurrencyName}
              />
            </div>
            <div className="vcBonus">
              <VirtualCurrencyAmount value={virtualBonusAmount} />{" "}
              <FormattedMessage
                id={`vc_bonus_${virtualCurrencyCode.toLowerCase()}`}
                defaultMessage={`Bonus ${virtualCurrencyName}`}
              />
            </div>
            <div className="vcTotal">
              <VirtualCurrencyAmount value={totalVc} />
            </div>
            <div className="vcTotalFormatted">
              <VirtualCurrencyAmount value={totalVc} />{" "}
              <FormattedMessage
                id={`vc_name_${virtualCurrencyCode.toLowerCase()}`}
                defaultMessage={virtualCurrencyName}
              />
            </div>
          </>
        )}
      </div>
      <div className="table">
        {virtualAmount && (
          <div className="rpRow">
            <div className="label">
              <FormattedMessage
                id={`vc_purchased_${virtualCurrencyCode.toLowerCase()}`}
                defaultMessage={`Total ${virtualCurrencyName}`}
              />
              :
            </div>
            <div className="value">
              <VirtualCurrencyAmount value={totalVc} />
            </div>
          </div>
        )}
        {renderBalance()}
        {renderDivider()}
        {renderTax()}
        <div className="totalRow">
          <div className="label">
            <FormattedMessage id="total" defaultMessage="Total" />:
          </div>
          <div className="value">{formattedGrandTotalAmount}</div>
        </div>
      </div>
    </div>
  );
};

PricePointCheckoutDetail.propTypes = {
  purchasedPricePoint: PropTypes.object.isRequired
};

export default PricePointCheckoutDetail;
