import React from "react";
import { Navigate } from "react-router-dom";

class PaymentCancelled extends React.Component {
  render() {
    return <Navigate to="/" />;
  }
}

export default PaymentCancelled;
