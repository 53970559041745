import PropTypes from "prop-types";
import React from "react";
import PaymentMethodItem from "./PaymentMethodItem";
import { injectIntl } from "react-intl";

class PaymentMethodList extends React.Component {
  render() {
    const { paymentOptions, platform, country, selectedPaymentMethodId, intl } = this.props;

    return (
      <div className="payment-method-options">
        <ul>
          {paymentOptions.map((option, index) => (
            <PaymentMethodItem
              key={index}
              onPaymentMethodSelect={this.props.onPaymentMethodSelect}
              selected={selectedPaymentMethodId === option.uniquePaymentMethodId}
              paymentMethodId={option.paymentMethodId}
              uniquePaymentMethodId={option.uniquePaymentMethodId}
              platform={platform}
              country={country}
              locale={intl.locale}
              currency={option.pricePoints[0].realCurrencyCode.toLowerCase()}
              position={index}
            />
          ))}
        </ul>
      </div>
    );
  }
}

PaymentMethodList.propTypes = {
  paymentOptions: PropTypes.array.isRequired,
  onPaymentMethodSelect: PropTypes.func.isRequired,
  country: PropTypes.object.isRequired,
  platform: PropTypes.string.isRequired,
  selectedPaymentMethodId: PropTypes.string
};

export default injectIntl(PaymentMethodList);
