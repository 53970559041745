import React from "react";
import PropTypes from "prop-types";

import ResultPage from "./ResultPage";
import PurchaseConsent from "../../containers/PurchaseConsent";
import { errorReasonEnum } from "../../redux/modules/error";
import { injectIntl } from "react-intl";

class KrErrorHandler extends React.Component {
  render() {
    let { errorReason, intl } = this.props;

    if (errorReason === errorReasonEnum.AUTH_ERROR) {
      return (
        <ResultPage
          isGift={false}
          purchaseDetails={{
            errorMessage: intl.formatMessage({ id: "payment_expired_text" })
          }}
          resultCode={"failed"}
        />
      );
    }

    if (errorReason === errorReasonEnum.CONSENT_INVALID) {
      return (
        <ResultPage
          isGift={false}
          purchaseDetails={{
            errorMessage: intl.formatMessage({ id: "kr_consent_invalid" })
          }}
          resultCode={"failed"}
        />
      );
    }

    if (errorReason === errorReasonEnum.CONSENT_REQUIRED) {
      return <PurchaseConsent />;
    }

    if (errorReason === errorReasonEnum.KR_NATIONAL_ID_REQUIRED) {
      return (
        <ResultPage
          isGift={false}
          purchaseDetails={{
            errorMessage: intl.formatMessage({ id: "kr_national_id_required" })
          }}
          resultCode={"failed"}
        />
      );
    }

    return (
      <ResultPage
        isGift={false}
        purchaseDetails={{
          errorMessage: intl.formatMessage({ id: "payment_error_text" })
        }}
        resultCode={"failed"}
      />
    );
  }
}

KrErrorHandler.propTypes = {
  errorReason: PropTypes.number
};

export default injectIntl(KrErrorHandler);
