import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const additionalCardOptions = [
  { value: "", label: "기타" },
  { value: "52", label: "제주" },
  { value: "35", label: "산업" },
  { value: "32", label: "광주" },
  { value: "48", label: "신협체크" },
  { value: "71", label: "우체국체크" },
  { value: "54", label: "MG새마을체크" },
  { value: "95", label: "저축은행체크" },
  { value: "56", label: "카카오뱅크" }
];

const creditCardBanksMap = {
  shinhan: { name: "신한카드", id: "14", pcOnly: false },
  nhbank: { name: "NH채움", id: "41", pcOnly: false },
  kookmin: { name: "KB Pay(국민)", id: "06", pcOnly: true },
  bccard: { name: "BC카드", id: "11", pcOnly: true },
  ourcard: { name: "우리카드", id: "38", pcOnly: true },
  hyundai: { name: "현대카드", id: "04", pcOnly: false },
  hansk: { name: "하나SK", id: "34", pcOnly: false },
  samsung: { name: "삼성카드", id: "12", pcOnly: false },
  forex: { name: "외환카드", id: "01", pcOnly: false },
  lotte: { name: "롯데카드", id: "03", pcOnly: false },
  citi: { name: "씨티카드", id: "43", pcOnly: false },
  enterprise: { name: "기업", id: "47", pcOnly: true },
  jeonbuk: { name: "전북카드", id: "33", pcOnly: true },
  chufa: { name: "수협카드", id: "51", pcOnly: true }
};

const PurchasePaymentMethodCard = ({ onSelectCardBank, selectedCreditCardBank, isWindows }) => {
  const macOnly = (bankCard) => {
    return !creditCardBanksMap[bankCard].pcOnly;
  };

  const all = () => {
    return true;
  };

  const onSelectCardBankRadio = (event) => {
    onSelectCardBank(event.target.value, event.target.title);
  };

  const onSelectAdditionalCard = (option) => {
    onSelectCardBank(option.value, option.title);
  };

  const getSelectedAdditionalCardValue = () => {
    for (let i = 0; i < additionalCardOptions.length; i++) {
      if (additionalCardOptions[i].value === selectedCreditCardBank) {
        return selectedCreditCardBank;
      }
    }

    return "";
  };

  const selectedAdditionalCardValue = getSelectedAdditionalCardValue();

  return (
    <div className="card">
      <h3>
        <FormattedMessage
          id="kr_purchase_payment_methods_credit_cards_title"
          defaultMessage="결제하실 신용카드를 선택하세요."
        />
      </h3>
      <ul>
        {Object.keys(creditCardBanksMap)
          .filter(isWindows ? all : macOnly)
          .map((ccbank) => (
            <li key={ccbank}>
              <label>
                <input
                  type="radio"
                  name="card"
                  value={creditCardBanksMap[ccbank].id}
                  title={creditCardBanksMap[ccbank].name}
                  checked={selectedCreditCardBank === creditCardBanksMap[ccbank].id}
                  onChange={onSelectCardBankRadio}
                />
                {creditCardBanksMap[ccbank].name}
              </label>
            </li>
          ))}
        {isWindows && (
          <li className={"additional-cards " + (selectedAdditionalCardValue ? "active" : "")}>
            <Dropdown
              options={additionalCardOptions}
              onChange={onSelectAdditionalCard}
              value={selectedAdditionalCardValue}
              placeholder="기타"
            />
          </li>
        )}
      </ul>
    </div>
  );
};

PurchasePaymentMethodCard.propTypes = {
  onSelectCardBank: PropTypes.func.isRequired,
  selectedCreditCardBank: PropTypes.string.isRequired,
  isWindows: PropTypes.bool.isRequired
};

export default PurchasePaymentMethodCard;
